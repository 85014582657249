import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Chip, PopoverMenu, RadioField, useControlledField, usePopoverMenu } from '@frontend/design-system';

type CustomFieldsLocationSelectorProps = {
  selectedLocationId: string;
  setSelectedLocationId: (locationId: string) => void;
  locationIds: string[];
};

export const CustomFieldsLocationSelector = ({
  selectedLocationId,
  setSelectedLocationId,
  locationIds,
}: CustomFieldsLocationSelectorProps) => {
  const { t } = useTranslation('scheduleSettings');
  const { getScopeName } = useAppScopeStore();

  const { getTriggerProps, getMenuProps, close, open, isOpen } = usePopoverMenu({
    placement: 'bottom-start',
    middlewareOptions: { offset: 0 },
  });
  const { ref: filterTriggerRef, ...triggerProps } = getTriggerProps();

  const handleLocationSelectorChange = (locationId: string) => {
    setSelectedLocationId(locationId);
    close();
  };

  const locationSelectorRadioFieldProps = useControlledField({
    type: 'radio',
    value: selectedLocationId,
    onChange: handleLocationSelectorChange,
  });

  return (
    <div ref={filterTriggerRef} css={{ display: 'flex', alignItems: 'center' }}>
      <Chip.DropdownFilter
        filterIsActive={selectedLocationId !== ''}
        {...triggerProps}
        onClick={isOpen ? close : open}
        css={{ width: 'unset', maxWidth: 'unset' }}
        leftElement={<Icon name='location-small' color={selectedLocationId === '' ? 'default' : 'white'} size={18} />}
      >
        {selectedLocationId === '' ? t('Location') : getScopeName(selectedLocationId)}
      </Chip.DropdownFilter>
      <PopoverMenu {...getMenuProps()} css={{ padding: theme.spacing(2), maxWidth: 250 }}>
        <RadioField {...locationSelectorRadioFieldProps} name='custom-fields-location-selector-type'>
          {locationIds.map((locationId) => (
            <RadioField.Radio value={locationId} key={locationId}>
              {getScopeName(locationId)}
            </RadioField.Radio>
          ))}
        </RadioField>
      </PopoverMenu>
    </div>
  );
};
