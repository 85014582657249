import { State } from '@weave/schema-gen-ts/dist/schemas/platform-location-feature/v1/platform-enums/location_feature_enum.pb';
import { useScopedAppFlagStore } from '@frontend/scope';

const enableStatesOfCF = [State.ACTIVE, State.TRIAL, State.PROMOTE];
const QUICK_FILL_CF_NAME = 'quickfill';

export const useQuickFillFeatureDetails = () => {
  const { getCustomizationFlagLocationMap } = useScopedAppFlagStore();

  const quickFillCFLocationMap = Object.fromEntries(
    Object.entries(getCustomizationFlagLocationMap(QUICK_FILL_CF_NAME) || {}).map(([locationId, cf]) => [
      locationId,
      cf?.state ? enableStatesOfCF.includes(cf.state) : false,
    ])
  );

  const isQuickFillCFEnabledForAnyLocation = Object.values(quickFillCFLocationMap).some((value) => !!value);

  const hasQuickFillFeature = isQuickFillCFEnabledForAnyLocation;

  return { hasQuickFillFeature, locationIdWiseQuickFillFeature: quickFillCFLocationMap };
};
