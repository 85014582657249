import { useCallback } from 'react';
import { State } from '@weave/schema-gen-ts/dist/schemas/platform-location-feature/v1/platform-enums/location_feature_enum.pb';
import type { ConfigType } from 'dayjs';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { useAppFlagStore } from '@frontend/shared';
import { formatDateTime, FormatDateTimeOptions } from '../utils/date-helpers';

const enableStatesOfCF = [State.ACTIVE, State.TRIAL, State.PROMOTE];

type SchedulingCF = 'quickfill' | 'onlinescheduling';

export const useSchedulingLocationInfo = () => {
  const state = useAppScopeStore();
  const { getCustomizationFlag } = useAppFlagStore();
  const { getCustomizationFlagLocationMap } = useScopedAppFlagStore();

  const getLocationTimeZone = useCallback((locationId: string) => {
    const location = state.accessibleLocationData?.[locationId];
    return location?.timezone || '';
  }, []);

  const formatLocationTime = useCallback(
    (inputDateTime: ConfigType, locationId: string, options?: Omit<FormatDateTimeOptions, 'fromTimezone'>) => {
      if (!locationId) {
        console.error('Location ID is required for date-time conversion');
        return '';
      }

      return formatDateTime(inputDateTime, { ...options, fromTimezone: getLocationTimeZone(locationId) });
    },
    []
  );

  const getCFLocationMap = useCallback(
    (feature: SchedulingCF) => {
      const cfLocationMap = getCustomizationFlagLocationMap(feature) || {};

      return Object.fromEntries(
        Object.entries(cfLocationMap).map(([locationId, cf]) => [
          locationId,
          cf?.state ? enableStatesOfCF.includes(cf.state) : false,
        ])
      );
    },
    [getCustomizationFlagLocationMap]
  );

  const getCFValueByLocationId = useCallback(
    (locationId: string, flagName: SchedulingCF) => {
      const cfMap = getCustomizationFlag({ locationIds: [locationId], flagName }) || {};
      const cfState = (cfMap[0] ?? {}).state;

      return cfState ? enableStatesOfCF.includes(cfState) : false;
    },
    [getCustomizationFlag]
  );

  return {
    isMultiLocation: !state.isSingleTypeScope,
    parentLocationId: state.selectedGroupId,
    getLocationTimeZone,
    formatLocationTime,
    getCFLocationMap,
    getCFValueByLocationId,
    ...state,
  };
};
