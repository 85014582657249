import { useState } from 'react';
import { RequestStatus } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { ScheduleRequestStatus } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { SendRequest } from '@weave/schema-gen-ts/dist/schemas/sms/send/v3/send_service.pb';
import dayjs from 'dayjs';
import { useMutation } from 'react-query';
import { ScheduleTypes } from '@frontend/api-schedule';
import { getUser } from '@frontend/auth-helpers';
import { ActionsUI } from '@frontend/contact-actions';
import { formatDate, getTodaysDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { SchemaSMSSendService } from '@frontend/schema';
import { theme } from '@frontend/theme';
import { Button, ButtonBar, ContentLoader, Heading, Text, Tray, useFormField, useAlert } from '@frontend/design-system';
import { ReadOnlyReviewAppointmentRequestForm } from '../../../../../../../components/ReadOnlyReviewAppointmentRequestForm';
import { useSchedulingLocationInfo } from '../../../../../../../hooks';
import { getFullName } from '../../../../../../../utils';
import { convertToTimezoneDateTime } from '../../../../../utils';
import { useScheduleActionsContext } from '../../../ScheduleActionsContext';
import { defaultTemplate } from '../../constant';
import { useScheduleRequestModalContext } from '../../Context/ScheduleRequestModalContext';
import { useApproveScheduleRequestQuery } from '../../hooks/useApproveScheduleRequestQuery';
import { useManageScheduleRequestModalDetails } from '../../hooks/useManageScheduleRequestModalDetails';
import { getRequestTags, replaceTemplateTags } from '../../utils';
import { ScheduleRequestModalManager } from '../ScheduleRequestModalManager';
import { ScheduleRequestModalTypeEnum } from '../types';

export const ScheduleReadOnlyRequestModal = () => {
  const { t } = useTranslation('scheduleCalendarRequest');
  const { getScopeName, getLocationTimeZone } = useSchedulingLocationInfo();
  const alert = useAlert();

  const [messageTemplate, setMessageTemplate] = useState(defaultTemplate);
  const [hasCustomDateTimeFields, setHasCustomDateTimeFields] = useState(false);
  const [modalType, setModalType] = useState<ScheduleRequestModalTypeEnum | ''>('');

  const { approveScheduleRequest, isLoading: isUpdateScheduleRequestLoading } = useApproveScheduleRequestQuery();

  const {
    handleCloseScheduleRequestModal,
    refetchScheduleRequestList,
    scheduleRequestModalProps,
    selectedScheduleRequest,
    isPaymentsEnabled,
  } = useScheduleRequestModalContext();

  const { closeModal: closeActionsModal, refetchAppointmentRequestCount } = useScheduleActionsContext();

  const manageScheduleRequestModalDetails = useManageScheduleRequestModalDetails(selectedScheduleRequest);

  const { appointmentTypes, providers } = manageScheduleRequestModalDetails;

  const paidAmount = !!selectedScheduleRequest?.bookingAmount ? selectedScheduleRequest.bookingAmount : 0;

  const selectedAppointmentTypeId = appointmentTypes.find(
    (type) => type.name === selectedScheduleRequest?.appointmentType
  )?.id;

  const selectedAppointmentTypeDuration = appointmentTypes.find(
    (type) => type.name === selectedScheduleRequest?.appointmentType
  )?.durationMinutes;

  const requestedProvider = providers.find(
    (provider) =>
      provider.id === selectedScheduleRequest?.practitionerId ||
      provider.publicDisplayName === selectedScheduleRequest.requestedOpenings?.[0]?.assets?.providers?.[0]
  );

  const requestedProviderName = requestedProvider
    ? requestedProvider.publicDisplayName || `${requestedProvider.firstName} ${requestedProvider.lastName}`
    : t('No Preference');

  const locationId = selectedScheduleRequest?.locationId || '';
  const locationName = getScopeName(locationId ?? selectedScheduleRequest?.locationId);
  const personId = selectedScheduleRequest?.schedulee?.id || '';
  const phoneNumber = selectedScheduleRequest?.schedulee?.phoneNumber || '';

  const { list: tags } = getRequestTags(
    selectedScheduleRequest?.dateTime ?? '',
    selectedScheduleRequest?.schedulee,
    locationName
  );

  const requestedDateTimes = selectedScheduleRequest?.requestedOpenings
    ? selectedScheduleRequest?.requestedOpenings?.map((opening) => opening.dateTime)
    : [];

  const selectedScheduleRequestDateTime = requestedDateTimes.length === 1 ? requestedDateTimes[0] : '';

  const requestedDateTimeProps = useFormField(
    {
      type: 'dropdown',
      value: hasCustomDateTimeFields ? '' : selectedScheduleRequestDateTime,
      required: !hasCustomDateTimeFields,
    },
    [hasCustomDateTimeFields]
  );

  const roundUpTime = () => {
    const minutes = dayjs().minute();
    const roundedMinutes = Math.ceil(minutes / 15) * 15;
    return dayjs().add(1, 'hour').minute(roundedMinutes).second(0).millisecond(0);
  };

  const customDateField = useFormField(
    {
      type: 'date',
      value: formatDate(roundUpTime(), 'MM/DD/YYYY'),
      required: hasCustomDateTimeFields,
      minDate: getTodaysDate('MM/DD/YYYY'),
    },
    [hasCustomDateTimeFields]
  );

  const customTimeField = useFormField(
    {
      type: 'time',
      value: formatDate(roundUpTime(), 'h:mm a'),
      required: hasCustomDateTimeFields,
      minTime: customDateField.value === getTodaysDate('MM/DD/YYYY') ? getTodaysDate('h:mm a') : '',
    },
    [hasCustomDateTimeFields]
  );

  const { onClick: messageOnClick } = ActionsUI.actions.useMessageAction();

  const { mutateAsync: sendSMS, isLoading: isSendSMSLoading } = useMutation({
    mutationFn: (req: SendRequest) => SchemaSMSSendService.Send(req),
    onError: () => {
      alert.error('Failed to send message');
    },
  });

  const getCustomDateTime = () => {
    if (hasCustomDateTimeFields && customDateField.value && customTimeField.value) {
      return dayjs(`${customDateField.value} ${customTimeField.value}`).format('MM/DD/YYYY h:mm a');
    }
    return '';
  };

  const getIsAppointmentDateValid = () => {
    const customDate = getCustomDateTime();
    const requestedDate = hasCustomDateTimeFields
      ? new Date(customDate ?? '')
      : new Date(requestedDateTimeProps.value ?? '');
    const currentDate = dayjs();
    const validDate = dayjs(requestedDate).isAfter(currentDate);
    return validDate;
  };

  const getSendSMSForScheduleRequestData = () => {
    const customDateTime = getCustomDateTime();

    const locationDateTime = convertToTimezoneDateTime({
      datetime: customDateTime || requestedDateTimeProps.value,
      timezone: getLocationTimeZone(locationId),
    });

    // Return if locationDateTime is not available
    if (!locationDateTime) return;

    const tags = getRequestTags(locationDateTime, selectedScheduleRequest?.schedulee, getScopeName(locationId));
    const templateText = replaceTemplateTags(messageTemplate, tags.map);
    const mobilePhone = selectedScheduleRequest?.schedulee?.phoneNumber ?? '';
    const messageOptions: SendRequest = {
      locationId: selectedScheduleRequest?.locationId ?? '',
      programSlugId: 'manual-messages',
      personPhone: mobilePhone,
      body: templateText,
      shortenUrls: true,
      createdBy: getUser()?.userID,
    };
    return messageOptions;
  };

  const getScheduleRequestData = () => {
    const dateTime = hasCustomDateTimeFields
      ? `${customDateField.value} ${customTimeField.value}`
      : requestedDateTimeProps.value;

    const approveData: ScheduleTypes.UpdateScheduleRequestType['input'] = {
      status: RequestStatus.ACCEPTED,
      reviewedBy: getUser()?.userID,
      id: selectedScheduleRequest?.id ?? '',
      locationId: selectedScheduleRequest?.locationId ?? '',
      appointmentTypeId: selectedAppointmentTypeId ?? '',
      practitionerId: requestedProvider?.id ?? '',
      bookingDuration: selectedAppointmentTypeDuration?.toString() ?? '',
      dateTime: dayjs(dateTime).toISOString(),
      scheduleRequestStatus: ScheduleRequestStatus.ACCEPTED,
    };
    return { approveData };
  };

  const approveRequest = () => {
    const { approveData } = getScheduleRequestData();

    const sendSMSForScheduleRequestData = getSendSMSForScheduleRequestData();
    const isAppointmentDateValid = getIsAppointmentDateValid();

    if (isAppointmentDateValid) {
      Promise.all([
        approveScheduleRequest({ ...approveData }),
        ...(sendSMSForScheduleRequestData ? [sendSMS(sendSMSForScheduleRequestData)] : []),
      ])
        .then((res) => {
          if (res) {
            scheduleRequestModalProps.closeModal();
            setModalType(ScheduleRequestModalTypeEnum.NO_WRITEBACKS_MESSAGE_SENT);
            refetchScheduleRequestList();
          }
        })
        .catch((err) => {
          if (err?.message?.includes('phone')) {
            setModalType(ScheduleRequestModalTypeEnum.MESSAGE_ERROR);
          } else {
            setModalType(ScheduleRequestModalTypeEnum.NO_WRITEBACKS_ERROR);
          }
        });
    } else {
      alert.error(t('Invalid date and time. Please select a future date and time.'));
    }
  };

  const handleCloseModal = () => {
    scheduleRequestModalProps.closeModal();
    handleCloseScheduleRequestModal();
    refetchScheduleRequestList();
    refetchAppointmentRequestCount();
  };

  const fullName = getFullName(selectedScheduleRequest.schedulee);

  const isLoading = isUpdateScheduleRequestLoading || isSendSMSLoading;

  const isComplete = hasCustomDateTimeFields
    ? !!customDateField.value && !!customTimeField.value
    : requestedDateTimeProps.value !== '';

  const disableApproveButton = !isComplete || isLoading;

  return (
    <>
      <Tray
        css={{ padding: 0, gap: 'unset' }}
        width='medium'
        {...scheduleRequestModalProps.modalProps}
        onClose={handleCloseScheduleRequestModal}
        autoFocusTimeout={3600000 * 2}
      >
        <ContentLoader show={isLoading} />
        <Tray.Header
          css={{
            alignItems: 'start',
            padding: theme.spacing(3, 3, 1, 3),
            borderBottom: `1px solid ${theme.colors.neutral10}`,
          }}
          Buttons={
            <Button
              variant='tertiary'
              label={t('Close Schedule Request Modal')}
              onClick={handleCloseScheduleRequestModal}
              iconName='x'
              size='large'
            />
          }
        >
          <header>
            <Heading level={2}>{t('Review Appointment Request')}</Heading>
            <Text css={{ marginTop: theme.spacing(1) }} size='medium' color='light'>
              {t('Review and confirm the appointment request details below.')}
            </Text>
          </header>
        </Tray.Header>
        <Tray.Body css={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <section css={{ height: '100%', backgroundColor: theme.colors.neutral5, minHeight: 400, overflowY: 'auto' }}>
            <ReadOnlyReviewAppointmentRequestForm
              requestDetails={selectedScheduleRequest}
              locationName={locationName}
              providerName={requestedProviderName}
              requestedDateTimes={requestedDateTimes as string[]}
              requestedDateTimeProps={requestedDateTimeProps}
              hasCustomDateTimeFields={hasCustomDateTimeFields}
              customDateField={customDateField}
              customTimeField={customTimeField}
              setHasCustomDateTimeFields={setHasCustomDateTimeFields}
              templateTags={tags}
              isReadOnlyTemplate={!isComplete}
              onTemplateChange={(template) => setMessageTemplate(template)}
              isPaymentsEnabled={isPaymentsEnabled}
              paidAmount={paidAmount}
            />
          </section>
          <footer
            css={{
              padding: theme.spacing(2),
              backgroundColor: theme.colors.white,
              position: 'static',
              bottom: 0,
              boxShadow: theme.shadows.heavy,
            }}
          >
            <ButtonBar>
              <Button
                variant='tertiary'
                label={t('Message Patient')}
                onClick={async () => {
                  await messageOnClick({ threadGroupId: locationId, personPhone: phoneNumber, personId });
                  handleCloseScheduleRequestModal();
                  closeActionsModal();
                }}
                iconName='message-small'
                color='primary'
              >
                {t('Message')}
              </Button>
              <Button
                variant='secondary'
                label={t('Close Schedule Request Modal')}
                onClick={handleCloseScheduleRequestModal}
              >
                {t('Cancel')}
              </Button>
              <Button
                variant='primary'
                label={t('Approve Request')}
                iconName='check-small'
                disabled={disableApproveButton}
                onClick={() => {
                  approveRequest();
                }}
              >
                {t('Approve')}
              </Button>
            </ButtonBar>
          </footer>
        </Tray.Body>
      </Tray>
      <ScheduleRequestModalManager
        modalType={modalType}
        onConfirm={() => console.log('onConfirm')}
        fullName={fullName}
        onClose={handleCloseModal}
        setModalType={setModalType}
      />
    </>
  );
};
