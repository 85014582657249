import { ScheduleRequest as ScheduleRequestV2 } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { BookingSubmission as ScheduleRequestV3 } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/booking_submission.pb';
import { ScheduleQueries } from '@frontend/api-schedule';
import { SchedulerV3 } from '@frontend/api-schedule-v3';

export const useGetScheduleRequest = (requestId: string, locationId: string) => {
  const { isScheduleV3FlagEnabled } = SchedulerV3.Helpers.useGetSchedulerV3FeatureFlagDetails();

  const scheduleRequestQuery = ScheduleQueries.useGetScheduleRequestById(
    {
      id: requestId,
      locationId,
    },
    {
      enabled: !isScheduleV3FlagEnabled,
    }
  );

  const scheduleRequestQueryV3 = SchedulerV3.Queries.useGetBookingSubmissionQuery({
    request: {
      submissionId: requestId,
    },
    options: {
      enabled: isScheduleV3FlagEnabled && !!requestId,
      select: (response) => {
        return response.bookingSubmission ? convertScheduleRequestV3ToV2(response.bookingSubmission) : undefined;
      },
    },
  });

  return isScheduleV3FlagEnabled ? scheduleRequestQueryV3 : scheduleRequestQuery;
};

function tryParseJson<T>(jsonString?: string): T | undefined {
  try {
    return JSON.parse(jsonString ?? '');
  } catch (error) {
    return undefined;
  }
}

type FormMetadataType = Record<string, string> & {
  gender?: string;
  isNewUser?: boolean;
};

export const convertScheduleRequestV3ToV2 = (scheduleRequest: ScheduleRequestV3): ScheduleRequestV2 => {
  const formMetadata = tryParseJson<FormMetadataType>(scheduleRequest.formMetadata);
  return {
    id: scheduleRequest.id,
    locationId: scheduleRequest.locationId,
    dateTime: scheduleRequest.createdAt,
    duration: scheduleRequest.slotDuration,
    // Note: schedulee and scheduler are the same in V2 (store patient info in it)
    schedulee: {
      firstName: scheduleRequest.firstName,
      lastName: scheduleRequest.lastName,
      phoneNumber: scheduleRequest.phoneNumber,
      address: scheduleRequest.addressInfo,
      birthDate: scheduleRequest.birthdate,
      email: scheduleRequest.email,
      gender: formMetadata?.gender ?? '',
      existingUser: !formMetadata?.isNewUser,
      insuranceInfo: scheduleRequest.formMetadata,
    },
    scheduler: {
      firstName: scheduleRequest.firstName,
      lastName: scheduleRequest.lastName,
      phoneNumber: scheduleRequest.phoneNumber,
      address: scheduleRequest.addressInfo,
      birthDate: scheduleRequest.birthdate,
      email: scheduleRequest.email,
      gender: formMetadata?.gender ?? '',
      existingUser: !formMetadata?.isNewUser,
      insuranceInfo: scheduleRequest.formMetadata,
    },
    note: scheduleRequest.note,
    appointmentTypeId: scheduleRequest.appointmentTypeId,
    practitionerId: scheduleRequest.providerId,
    reviewedBy: scheduleRequest.reviewedBy,
    requestedOpenings: (scheduleRequest.requestedSlots ?? []).map((slot) => ({ dateTime: slot })),
  };
};
