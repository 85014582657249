import { FC } from 'react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, PopoverMenu, PopoverMenuItem, usePopoverMenu } from '@frontend/design-system';
import { CommonActionTrackingIds, getPaymentHVATrackingIds } from '../../tracking-ids';

interface DismissButtonProps {
  source: string;
  onDismiss: (snoozeDays?: number) => void;
}
export const DismissButton: FC<DismissButtonProps> = ({ source, onDismiss }) => {
  const { getMenuProps, getTriggerProps, getItemProps } = usePopoverMenu({ placement: 'bottom-end' });
  const { t } = useTranslation('highValueAdoption', { keyPrefix: 'payments' });
  return (
    <>
      <Button
        variant='tertiary'
        trackingId={getPaymentHVATrackingIds({
          source,
          trackingId: CommonActionTrackingIds.DISMISS,
        })}
        css={{ color: theme.colors.neutral50 }}
        {...getTriggerProps()}
      >
        {t('Dismiss')}
      </Button>
      <PopoverMenu {...getMenuProps()}>
        <PopoverMenuItem
          trackingId={getPaymentHVATrackingIds({
            source,
            trackingId: CommonActionTrackingIds.REMIND_IN_3_DAYS,
          })}
          {...getItemProps({ index: 0, onClick: () => onDismiss?.(3) })}
        >
          {t('Snooze for 3 days')}
        </PopoverMenuItem>
        <PopoverMenuItem
          trackingId={getPaymentHVATrackingIds({
            source,
            trackingId: CommonActionTrackingIds.NEVER_SHOW_AGAIN,
          })}
          {...getItemProps({ index: 1, onClick: () => onDismiss?.() })}
        >
          {t('Never show again')}
        </PopoverMenuItem>
      </PopoverMenu>
    </>
  );
};
