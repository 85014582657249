import React from 'react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { ScheduleQueries } from '@frontend/api-schedule';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Modal, useModalControl, SpinningLoader, styles, ModalControlModalProps } from '@frontend/design-system';
import { AppointmentEventCard } from '../components/schedule-calendar-components/appointment-event-card';
import { PersonStatusIndicator } from '../components/schedule-calendar-components/appointment-event-card/person-status-indicator';
import { useAppointmentsInfoShallowStore } from './use-appointments-info-store';

type UseGetAppointmentDetailsProps = {
  appointmentId: string;
  locationId: string;
  enabled: boolean;
  appointments?: Appointment[];
  eventColor?: string;
  onModalClose?: () => void;
};

export const useGetAppointmentDetails = ({
  appointmentId,
  locationId,
  onModalClose,
  eventColor = theme.colors.warning50,
  appointments,
  enabled,
}: UseGetAppointmentDetailsProps) => {
  const modal = useModalControl();
  const getAppointmentQuery = ScheduleQueries.useGetAppointment({
    appointmentId,
    locationId,
    isEnabled: enabled && modal.modalProps.show,
  });

  const handleOnCloseModal = () => {
    onModalClose?.();
    modal?.closeModal();
  };

  return {
    Modal: (
      <AppointmentDetailsModal
        onCloseModal={handleOnCloseModal}
        modalProps={modal.modalProps}
        appointmentId={appointmentId}
        locationId={locationId}
        eventColor={eventColor}
        appointments={appointments}
        isEnabled={enabled}
      />
    ),
    triggerProps: { ...modal.triggerProps },
    closeModal: handleOnCloseModal,
    getAppointmentCardData: {
      ...getAppointmentQuery,
    },
  };
};

type AppointmentDetailsModalProps = {
  modalProps: ModalControlModalProps;
  appointmentId: string;
  locationId: string;
  isEnabled: boolean;
  onCloseModal: () => void;
  appointments?: Appointment[];
  eventColor?: string;
};

const AppointmentDetailsModal = React.memo(
  ({
    modalProps,
    locationId,
    appointmentId,
    eventColor,
    appointments,
    onCloseModal,
    isEnabled,
  }: AppointmentDetailsModalProps) => {
    const { getScopeName } = useAppScopeStore();

    const { appointments: calendarViewAppointments } = useAppointmentsInfoShallowStore('appointments');

    const appointmentsToFindExistingAppointment = appointments ? appointments : calendarViewAppointments;

    const existsInFetchedAppointments = appointmentsToFindExistingAppointment?.find(
      (appointment: Appointment) => appointment.id === appointmentId
    );

    const { onClose, ...restModalProps } = modalProps;

    const onModalClose = () => {
      onClose();
      onCloseModal();
    };

    const { data: { appointment } = {}, isLoading: isAppointmentDataLoading } = ScheduleQueries.useGetAppointment({
      appointmentId,
      locationId,
      isEnabled: isEnabled && !existsInFetchedAppointments && modalProps.show,
    });

    const appointmentDetails = !!existsInFetchedAppointments ? existsInFetchedAppointments : appointment;

    const personStatus = appointmentDetails?.person?.status ?? '';

    if (!appointmentDetails) return null;

    return (
      <Modal
        disableCloseOnEscape={true}
        onClose={onModalClose}
        {...restModalProps}
        css={{
          maxWidth: '600px',
          padding: theme.spacing(3),
          paddingLeft: eventColor ? theme.spacing(4) : theme.spacing(3),
        }}
      >
        {isAppointmentDataLoading ? (
          <AppointmentDetailsModalSpinner />
        ) : (
          <>
            <PersonStatusIndicator personStatus={personStatus} />
            {eventColor && (
              <div
                css={{
                  backgroundColor: eventColor ?? '',
                  width: theme.spacing(1),
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  borderTopLeftRadius: theme.borderRadius.medium,
                  borderBottomLeftRadius: theme.borderRadius.medium,
                }}
              />
            )}
            <AppointmentEventCard
              appointment={appointmentDetails ?? {}}
              closeAppointmentDetailsModal={onModalClose}
              eventId={appointmentDetails?.id ?? ''}
              locationName={getScopeName(locationId) ?? '-'}
            />
          </>
        )}
      </Modal>
    );
  }
);

AppointmentDetailsModal.displayName = 'AppointmentDetailsModal';

const AppointmentDetailsModalSpinner = () => (
  <div css={[styles.flexCenter, { width: '500px', height: '300px' }]}>
    <SpinningLoader />
  </div>
);
