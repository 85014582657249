import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing(4)};
  margin-top: ${theme.spacing(1)};
  padding: ${theme.spacing(0, 0.5)};
`;
