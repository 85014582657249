import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${theme.colors.neutral20};
  padding-inline-end: ${theme.spacing(2)};
`;

export const searchFieldStyle = css`
  margin: ${theme.spacing(1, 0, 2)};
  min-width: 262px;
`;

export const listContainerStyle = css`
  flex: 1;
  min-height: 300px;
  min-width: 180px;
`;

export const emptyResultStyle = css`
  color: ${theme.colors.text.subdued};
  text-align: center;
  max-width: 260px;
`;
