import {
  PhoneHours,
  PhoneHoursScheduleRule,
  ReadPhoneHoursResponse,
  ScheduleCallRoute,
  ScheduleType,
} from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/phone_hours.pb';
import { useTranslation } from '@frontend/i18n';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { Tray, useModalControl, Text, Heading, TextLink, TextField } from '@frontend/design-system';
import { PhoneHoursGrid } from './phone-hours-grid';
import { useScheduleForm } from './phone-hours-schedule.form';

interface IPhoneHoursEditTray {
  modalControls: ReturnType<typeof useModalControl>;
  mountTarget: string;
  scheduleCallRoute: ScheduleCallRoute;
  selectedPhoneHour: ReadPhoneHoursResponse;
  phoneHoursDataWithSchedule: PhoneHours[];
}

export const PhoneHoursEditTray = ({
  modalControls,
  mountTarget,
  scheduleCallRoute,
  selectedPhoneHour,
  phoneHoursDataWithSchedule,
}: IPhoneHoursEditTray) => {
  const { t } = useTranslation('phone');
  const { navigate } = useSettingsNavigate();

  const DEFAULT_SCHEDULE: PhoneHoursScheduleRule = {
    scheduleId: '',
    name: '',
    type: ScheduleType.BREAK,
    rules: [],
  };

  const schedule = selectedPhoneHour.phoneHours[1] ?? DEFAULT_SCHEDULE;
  const form = useScheduleForm({ selectedSchedule: schedule, filteredSchedules: phoneHoursDataWithSchedule });
  const { formProps: scheduleFormProps, values: scheduleValues, getFieldProps: scheduleGetFieldProps } = form;

  const phoneHoursNameFieldProps = scheduleGetFieldProps('phoneHoursNameField');

  return (
    <Tray {...modalControls.modalProps} mountTarget={mountTarget} width='xlarge'>
      <form {...scheduleFormProps}>
        <Tray.Header>
          <Heading level={2}>{t('Edit Phone Hours')}</Heading>
        </Tray.Header>
        <Text size='medium' css={{ marginBottom: theme.spacing(2) }}>
          {t('Set the hours for the days and time in which your phone is available for calls.')}
        </Text>
        <div css={{ display: 'flex', gap: theme.spacing(2), flexDirection: 'column' }}>
          <div css={{ borderBottom: `1px solid ${theme.colors.neutral20}`, paddingBottom: theme.spacing(2) }}>
            <Text color='light' size='medium'>
              {t('Call Route')}
            </Text>
            <TextLink
              onClick={() =>
                navigate({
                  to: '/phone/call-routes/:id',
                  params: { id: scheduleCallRoute.id ?? '' },
                })
              }
            >
              {scheduleCallRoute.name}
            </TextLink>
          </div>
          <TextField {...phoneHoursNameFieldProps} label='Phone Hours Name' css={{ maxWidth: theme.spacing(40) }} />
          <PhoneHoursGrid scheduleGetFieldProps={scheduleGetFieldProps} scheduleValues={scheduleValues} />
        </div>
      </form>
    </Tray>
  );
};
