import { ReactNode, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { AppointmentPerson } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { AppointmentType, Provider } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import dayjs from 'dayjs';
import { sortBy } from 'lodash-es';
import { useInfiniteQuery } from 'react-query';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { PersonAPI, PersonTypes } from '@frontend/api-person';
import { ScheduleTypes } from '@frontend/api-schedule';
import { InfinitePaginatedList } from '@frontend/components';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  Chip,
  DatePickerField,
  DropdownField,
  IconButton,
  NakedButton,
  Tray,
  SpinningLoader,
  Text,
  TimeField,
  useDebouncedValue,
  useForm,
  useFormField,
  useAlert,
  ConfirmationModal,
  useModalControl,
} from '@frontend/design-system';
import { DropdownListSelector, useDropdownListSelector } from '../../../../components/DropdownListSelector';
import { useCalendarEventsConfigurationShallowStore, useGetIntegrationDetails } from '../../../../hooks';
import { getFullName } from '../../../../utils';
import { getDateString, getTodaysDate } from '../../../../utils/date-helpers';
import { ProviderDropdownListSelector } from '../../components/ProviderDropdownListSelector';
import { UserIconContainer } from '../../components/UserIconContainer';
import { transformToLegacyPerson } from '../../helpers';
import { isUUID } from '../../utils';
import { calendarEventsTrackingIds } from './calendar-events-tracking-ids';
import {
  AppointmentAvailabilityFormValues,
  OnCreateCalendarEvent,
  OnDeleteCalendarEvent,
  OnUpdateCalendarEvent,
} from './types';

export const CACHE_AND_STALE_TIME = 1000 * 60 * 5; // 5 minute
const PERSON_LIST_API_RESPONSE_LIMIT = 20;

const PROVIDER_SELECTOR_MOBILE_WIDTH = 176;
const PROVIDER_SELECTOR_DESKTOP_WIDTH = 220;

interface NewAppointmentFormProps {
  selectedLocationIds: string[];
  isScheduleV3: boolean;
  appointmentTypes: AppointmentType[];
  providersList: Provider[];
  availableAppointmentTimes: ScheduleTypes.OpeningBlock[];
  isAvailableAppointmentTimesLoading: boolean;
  selectedLocationId: string;
  closeModal: () => void;
  refetchAppointmentTypes: () => void;
  setSelectedFormValuesForAvailabilityApi: (
    appointmentAvailabilityFormValues: AppointmentAvailabilityFormValues
  ) => void;
  onCreateAppointment: (payload: OnCreateCalendarEvent) => void;
  onDeleteAppointment: ({ id, createdByLocationId }: OnDeleteCalendarEvent) => void;
  onUpdateAppointment: (payload: OnUpdateCalendarEvent) => void;
  refetchAppointments?: (locationId?: string) => void | Promise<void>;
  setSelectedLocationId: (locationId: string) => void;
}

export const NewAppointmentForm = ({
  selectedLocationIds,
  isScheduleV3,
  appointmentTypes,
  providersList,
  availableAppointmentTimes,
  isAvailableAppointmentTimesLoading,
  selectedLocationId,
  closeModal,
  refetchAppointmentTypes,
  onDeleteAppointment,
  onUpdateAppointment,
  onCreateAppointment,
  refetchAppointments,
  setSelectedFormValuesForAvailabilityApi,
  setSelectedLocationId,
}: NewAppointmentFormProps) => {
  const isMobile = useMatchMedia({ maxWidth: breakpoints.small.min });
  const { t } = useTranslation('scheduleCalendarEvents');
  const alerts = useAlert();

  const { modalProps: deleteConfirmationModalProps, triggerProps: deleteConfirmationModalTriggerProps } =
    useModalControl();
  const { getScopeName, accessibleLocationData } = useAppScopeStore();
  const { demoSourceIds } = DataSourcesHooks.useDemoLocationSourceIdsShallowStore('demoSourceIds');
  const { configuredCalendarEvent, setConfiguredCalendarEvent } = useCalendarEventsConfigurationShallowStore(
    'configuredCalendarEvent',
    'setConfiguredCalendarEvent'
  );

  const [isSavingAppointment, setIsSavingAppointment] = useState<boolean>(false);

  const [singleSourceId, setSingleSourceId] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchTerm = useDebouncedValue(searchValue, 500);

  const [personValue, setPersonValue] = useState<AppointmentPerson>({});
  const [selectedTime, setSelectedTime] = useState<string>(configuredCalendarEvent?.startHour ?? '');
  const [personDisplayValue, setPersonDisplayValue] = useState<string>(configuredCalendarEvent?.name ?? '');

  const patientDropdownListSelectorProps = useDropdownListSelector({
    value: '',
  });

  const providerDropdownListSelectorProps = useDropdownListSelector({
    value: configuredCalendarEvent?.providerId ?? '',
  });

  const timeFieldProps = useFormField({
    type: 'time',
    value: '',
    required: false,
  });

  const locationFieldProps = useFormField({
    type: 'dropdown',
    value: configuredCalendarEvent?.locationId ? configuredCalendarEvent?.locationId : selectedLocationIds?.[0] ?? '',
    required: true,
  });

  useEffect(() => {
    if (locationFieldProps.value) {
      setSelectedLocationId(locationFieldProps.value);
    }
  }, [locationFieldProps.value]);

  const parentLocationId = accessibleLocationData?.[selectedLocationId]?.groupId;

  const {
    data: integrations,
    refetch: refetchIntegrations,
    isIntegratedOffice,
    isLoading,
  } = useGetIntegrationDetails({
    selectedLocationId,
    parentLocationId,
  });

  const shouldRenderSourceDropdown = isIntegratedOffice && !isLoading && !!integrations && integrations.length > 1;

  const {
    getFieldProps,
    values,
    seedValues,
    reset: resetAppointmentForm,
    isComplete: isAppointmentFormComplete,
  } = useForm({
    fields: {
      source: {
        type: 'dropdown',
        value: '',
        required: shouldRenderSourceDropdown,
      },
      appointmentType: {
        type: 'dropdown',
        value: '',
        required: true,
      },
      appointmentDate: {
        type: 'datePicker',
        value: '',
        required: true,
        minDate: getTodaysDate(),
      },
    },
  });

  // Set the source if the location has a single source or is non integrated
  useEffect(() => {
    const isNonIntegratedOrSingleSource =
      integrations && integrations.length > 0 && !isScheduleV3 && (integrations.length === 1 || !isIntegratedOffice);

    if (isNonIntegratedOrSingleSource) {
      setSingleSourceId(integrations[0].SourceID);
      seedValues({
        ...values,
        source: integrations[0].SourceID,
      });
    }

    // TODO: Add logic to set the source tenant id for v3
  }, [integrations, isScheduleV3]);

  const infiniteQueryProps = useInfiniteQuery({
    queryKey: ['personsV3', debouncedSearchTerm, selectedLocationId],
    queryFn: async ({ pageParam }) => {
      const res = await PersonAPI.getPersonsV3({
        responseLimit: PERSON_LIST_API_RESPONSE_LIMIT,
        statusFilter: '',
        search: debouncedSearchTerm,
        pageToken: pageParam,
        locationId: selectedLocationId ?? '',
        sourceIds: demoSourceIds,
      });

      return {
        nextOffset: res.nextOffset,
        rows: sortBy(
          res.rows.map((person) => transformToLegacyPerson(person)),
          [(person) => (person.LastName ? person.LastName?.toLowerCase() : person.FirstName?.toLowerCase())]
        ),
      };
    },
    getNextPageParam: ({ nextOffset, rows }) => {
      if (rows.length < PERSON_LIST_API_RESPONSE_LIMIT) return undefined;
      return nextOffset;
    },
    cacheTime: CACHE_AND_STALE_TIME, // 5 minute
    staleTime: CACHE_AND_STALE_TIME, // 5 minute
    enabled: !!selectedLocationId,
  });

  useEffect(() => {
    const appointmentTypeId = appointmentTypes?.find(
      (type) => type?.name?.toLowerCase() === configuredCalendarEvent?.appointmentType?.toLowerCase()
    )?.id;

    seedValues({
      appointmentType: appointmentTypeId ?? '',
      appointmentDate: getDateString(configuredCalendarEvent?.calendarDateValue),
      source: configuredCalendarEvent?.createdBySourceId ?? '',
    });

    const configuredAppointmentEventDate =
      configuredCalendarEvent?.startHour && configuredCalendarEvent?.calendarDateValue
        ? dayjs(`${configuredCalendarEvent.calendarDateValue} ${configuredCalendarEvent.startHour}`)?.toISOString()
        : '';

    setSelectedTime(configuredAppointmentEventDate);
    providerDropdownListSelectorProps.onChange(configuredCalendarEvent?.providerId ?? '');
    patientDropdownListSelectorProps.onChange(configuredCalendarEvent?.patientId ?? '');
  }, [appointmentTypes, configuredCalendarEvent]);

  const clearAppointmentForm = ({ shouldClearSourceId = true }) => {
    resetAppointmentForm();
    refetchAppointmentTypes();
    refetchIntegrations();
    providerDropdownListSelectorProps.onChange('');
    patientDropdownListSelectorProps.onChange('');
    setConfiguredCalendarEvent(null);
    setSelectedTime('');
    shouldClearSourceId && setSingleSourceId('');
  };

  const cancelButtonHandler = () => {
    clearAppointmentForm({ shouldClearSourceId: true });
    closeModal();
  };

  const selectPerson = (person: PersonTypes.Person) => {
    const { FirstName, LastName } = person;
    const displayName = getFullName(person);
    setPersonDisplayValue(displayName);
    setPersonValue({
      firstName: FirstName,
      lastName: LastName,
      personId: person.PersonID,
      email: person.Email,
      mobilePhone: person.MobilePhone,
      homePhone: person.HomePhone,
      sourceId: person.SourceID,
      preferredName: person.PreferredName ?? '',
      pmId: person.PMID ?? '',
      pmDisplayId: person.PMDisplayID ?? '',
      gender: person.Gender,
      status: person.Status,
      birthDate: person.Birthdate,
    });
  };

  const submitAppointmentForm = async () => {
    setIsSavingAppointment(true);
    const appointmentType = appointmentTypes?.find((type) => type.id === values.appointmentType);

    const provider = providersList.find((provider) => provider.id === providerDropdownListSelectorProps.value);
    const practitionerName = `${provider?.firstName} ${provider?.lastName}`;

    try {
      const startDate = getAppointmentStartDate();
      const appointmentData: OnCreateCalendarEvent = {
        duration: appointmentType?.durationMinutes ?? 0,
        locationId: selectedLocationId,
        personId: patientDropdownListSelectorProps.value,
        practitionerId: providerDropdownListSelectorProps.value,
        startDate,
        type: values.appointmentType || '',
      };

      if (configuredCalendarEvent?.eventId) {
        await onUpdateAppointment({
          id: configuredCalendarEvent?.eventId,
          referenceId: configuredCalendarEvent?.referenceId,
          practitionerCalendarId: provider?.parentProviderId ?? '',
          person: personValue,
          appointmentTypeId: appointmentType?.id || '',
          appointmentTypeName: appointmentType?.name || '',
          practitionerName,
          ...appointmentData,
        });
        alerts.success(t('Successfully updated appointment'));
      } else {
        const provider = providersList.find((provider) => provider.id === providerDropdownListSelectorProps.value);
        await onCreateAppointment({
          ...appointmentData,
          practitionerName,
          person: personValue,
          practitionerCalendarId: provider?.parentProviderId ?? '',
          sourceId: values?.source || singleSourceId,
          appointmentTypeId: values.appointmentType || '',
          appointmentTypeName: appointmentType?.name || '',
        });
        alerts.success(t('Successfully created appointment'));
      }
      setConfiguredCalendarEvent(null);
      refetchAppointments?.(selectedLocationId);
      closeModal();
    } catch (error: any) {
      console.error(error);
      const errorMessage = configuredCalendarEvent?.eventId
        ? 'Failed to update appointment'
        : 'Failed to create appointment';
      alerts.error(errorMessage);
    } finally {
      setIsSavingAppointment(false);
    }
  };

  const customProviderList = useMemo(() => {
    if (values.appointmentType) {
      const availableProvidersByAppointmentTypes = appointmentTypes
        ?.find((appointmentType) => appointmentType.id === values.appointmentType)
        ?.providerAssets?.filter((provider) => provider.available);

      // Display providers who match the selected source id (if a provider has the source id property) and are available for the selected appointment type
      return providersList.filter(({ id, sourceId }) => {
        const isValidUUID = isUUID(id ?? '');
        if (!isValidUUID) return false;

        if (!!sourceId) {
          return availableProvidersByAppointmentTypes?.some(
            (providerAssetId) => providerAssetId.id === id && values.source === sourceId
          );
        } else {
          return availableProvidersByAppointmentTypes?.some((providerAssetId) => providerAssetId.id === id);
        }
      });
    }
    return [];
  }, [values.appointmentType, providersList]);

  useEffect(() => {
    if (timeFieldProps.value || values.appointmentDate) {
      const appointmentDateValue = values.appointmentDate || configuredCalendarEvent?.calendarDateValue;

      const appointmentTimeValue = timeFieldProps.value || configuredCalendarEvent?.startHour;

      const selectedDateTime = !!appointmentDateValue
        ? dayjs(`${appointmentDateValue} ${appointmentTimeValue}`)?.toISOString()
        : '';

      setSelectedTime(selectedDateTime);
    }
  }, [timeFieldProps.value, values.appointmentDate]);

  useEffect(() => {
    const hasFieldValues = !!(
      providerDropdownListSelectorProps.value &&
      values.appointmentType &&
      values.appointmentDate &&
      locationFieldProps.value
    );

    const duration = appointmentTypes?.find((type) => type.id === values.appointmentType)?.durationMinutes;

    if (hasFieldValues && duration) {
      const startDate = selectedTime ? dayjs(selectedTime).toISOString() : '';
      const endDate = selectedTime ? dayjs(startDate).add(duration, 'minutes').toISOString() : '';

      const provider = providersList.find((provider) => provider.id === providerDropdownListSelectorProps.value);

      setSelectedFormValuesForAvailabilityApi({
        appointmentDate: values.appointmentDate || '',
        appointmentType: values.appointmentType || '',
        selectedLocationId: locationFieldProps.value,
        selectedPractitionerId: providerDropdownListSelectorProps.value,
        startDate: selectedTime,
        endDate: endDate,
        duration,
        selectedPractitionerCalendarId: provider?.parentProviderId ?? '',
      });
    }
  }, [
    providerDropdownListSelectorProps.value,
    selectedTime,
    values.appointmentType,
    values.appointmentDate,
    locationFieldProps.value,
  ]);

  const getAppointmentStartDate = () => {
    if (selectedTime) return dayjs(selectedTime).toISOString();

    if (values.appointmentDate !== configuredCalendarEvent?.calendarDateValue) {
      const appointmentDateValue = values.appointmentDate || configuredCalendarEvent?.calendarDateValue;

      let appointmentTime = configuredCalendarEvent?.startHour;
      if (configuredCalendarEvent?.startHour && timeFieldProps.value) {
        const calendarEventStartHourIn24Hr = dayjs(configuredCalendarEvent?.startHour, 'h:mm a').format('HH:mm:ss');
        appointmentTime =
          calendarEventStartHourIn24Hr !== timeFieldProps.value ? timeFieldProps.value : calendarEventStartHourIn24Hr;
      }
      return !!appointmentDateValue ? dayjs(`${appointmentDateValue} ${appointmentTime}`)?.toISOString() : '';
    }
    return selectedTime;
  };

  const timeSelectionHelperText = () => {
    if (configuredCalendarEvent?.startHour) {
      return;
    }
    if (!values.appointmentDate) {
      return (
        <Chip variant='disabled' css={{ maxWidth: '220px' }}>
          {t('Select Date First')}
        </Chip>
      );
    }
    if (!availableAppointmentTimes?.length && selectedTime.length === 0) {
      return (
        <Chip variant='disabled' css={{ maxWidth: '220px' }}>
          {t('No Available Times')}
        </Chip>
      );
    }
    return null;
  };

  const handleDeleteAppointmentEventTypeSelection = () => {
    closeModal();
    onDeleteAppointment({
      id: configuredCalendarEvent?.eventId || '',
      createdByLocationId: configuredCalendarEvent?.locationId || '',
    });
    refetchAppointments?.(configuredCalendarEvent?.locationId);
  };

  const isFormValid =
    isAppointmentFormComplete &&
    providerDropdownListSelectorProps.value &&
    patientDropdownListSelectorProps.value &&
    selectedTime;

  return (
    <div css={newAppointmentFormContainerStyles}>
      <div css={{ marginTop: theme.spacing(1) }}>
        <Text size='small' color='light'>
          {!!configuredCalendarEvent?.eventId ? t('Manage Appointment') : t('New Appointment')}
        </Text>
        <div css={{ marginTop: theme.spacing(2) }}>
          <DropdownListSelector
            placeHolder={t('Select Patient')}
            placeHolderFontSize={24}
            name='search-patient-selector'
            shouldHaveBorder={false}
            icon={<UserIconContainer width={32} height={32} />}
            listWidth={300}
            height={48}
            shouldShowOnlyDisplayValueOnSelect={true}
            setSearchValue={setSearchValue}
            displayValue={personDisplayValue}
            isDisabled={!!configuredCalendarEvent?.eventId}
            {...patientDropdownListSelectorProps}
          >
            <InfinitePaginatedList
              height={360}
              infiniteQueryProps={infiniteQueryProps}
              renderListItem={({ listItem }): ReactNode => {
                // Type cased to Person type to avoid type errors
                const personDetails = listItem as unknown as PersonTypes.Person;
                const { PersonID, FirstName, LastName, HomePhone, MobilePhone, Email } = personDetails;
                return (
                  <div onClick={() => selectPerson(personDetails)}>
                    <DropdownListSelector.Option
                      key={PersonID}
                      displayValue={`${FirstName} ${LastName}`}
                      value={PersonID}
                      searchValue={patientDropdownListSelectorProps.value ?? ''}
                    >
                      <Text size='large' css={{ marginTop: theme.spacing(1) }}>{`${FirstName} ${LastName}`}</Text>
                      <Text color='light' css={{ marginBottom: theme.spacing(1) }}>{`${
                        formatPhoneNumber(MobilePhone) || formatPhoneNumber(HomePhone) || Email || ''
                      }`}</Text>
                    </DropdownListSelector.Option>
                  </div>
                );
              }}
            />
          </DropdownListSelector>
        </div>

        {selectedLocationIds.length > 1 && (
          <div css={fieldContainerStyles}>
            <Text>{t('Location')}</Text>
            <DropdownField
              css={{ width: '220px' }}
              label={t('{{count}} Locations', { count: selectedLocationIds.length })}
              name='location-selection'
              {...locationFieldProps}
            >
              {selectedLocationIds.map((locationId) => {
                const locationName = getScopeName(locationId);
                return (
                  <DropdownField.Option key={locationId} value={locationId} searchValue={locationName ?? ''}>
                    <div
                      css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}
                      onClick={() => clearAppointmentForm({ shouldClearSourceId: false })}
                    >
                      <Icon name='location' size={16} color='light' />
                      <Text>{locationName}</Text>
                    </div>
                  </DropdownField.Option>
                );
              })}
            </DropdownField>
          </div>
        )}
        <div css={fieldContainerStyles}>
          <Text>{t('Type')}</Text>
          <DropdownField css={{ width: '220px' }} label={t('Select Type')} {...getFieldProps('appointmentType')}>
            {appointmentTypes?.map((type) => {
              return (
                <DropdownField.Option key={type.id} value={type.id || ''}>
                  {type.name}
                </DropdownField.Option>
              );
            })}
          </DropdownField>
        </div>
        {shouldRenderSourceDropdown && (
          <div css={fieldContainerStyles}>
            <Text>{t('Source')}</Text>
            <DropdownField
              css={{ width: '220px' }}
              label={t('Select Source')}
              disabled={!!configuredCalendarEvent?.eventId}
              {...getFieldProps('source')}
            >
              {integrations?.map((source) => {
                return (
                  <DropdownField.Option key={source.SourceID} value={source.SourceID}>
                    {source.SourceName}
                  </DropdownField.Option>
                );
              })}
            </DropdownField>
          </div>
        )}
        <div css={providerFieldContainerStyles}>
          <Text>{t('Provider')}</Text>
          <ProviderDropdownListSelector
            providerDropdownListSelectorProps={providerDropdownListSelectorProps}
            listWidth={isMobile ? PROVIDER_SELECTOR_MOBILE_WIDTH : PROVIDER_SELECTOR_DESKTOP_WIDTH}
            css={{ width: '220px', padding: theme.spacing(1, 0) }}
            customProviderList={customProviderList}
            hideLocationTag={true}
            locationIdsToFilterProviders={[locationFieldProps.value]}
            filterProviderByProviderUUID={true}
            providersList={providersList}
            selectedLocationIds={selectedLocationIds}
          />
        </div>
        <div css={fieldContainerStyles}>
          <Text>{t('Date')}</Text>
          <DatePickerField css={{ width: '220px' }} label={t('Date')} {...getFieldProps('appointmentDate')} />
        </div>
        <div css={[fieldContainerStyles, timeFieldContainerStyles]}>
          <Text>{t('Time')}</Text>
          <div css={{ width: '220px' }}>
            {values.appointmentDate && (
              <TimeField
                {...timeFieldProps}
                label={t('Start Time')}
                interval={5}
                name='start-time'
                css={{ marginBottom: theme.spacing(2) }}
              />
            )}
            {isAvailableAppointmentTimesLoading && <SpinningLoader size='medium' />}
            {timeSelectionHelperText()}
            {selectedTime ? (
              <>
                <NakedButton
                  key={selectedTime}
                  onClick={() => setSelectedTime('')}
                  css={[timeButtonStyles, selectedTimeButtonStyles]}
                >
                  {formatDate(selectedTime, 'h:mm a')}
                  <Icon name='x' css={xIconStyles} size={16} />
                </NakedButton>
              </>
            ) : (
              <div css={availableTimeSlotContainer}>
                {availableAppointmentTimes?.map((time) => (
                  <NakedButton
                    key={time.startTime}
                    onClick={() => setSelectedTime(time.startTime)}
                    css={timeButtonStyles}
                  >
                    {formatDate(time.startTime, 'h:mm a')}
                  </NakedButton>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <>
        {!!configuredCalendarEvent?.eventId && !isIntegratedOffice ? (
          <>
            <IconButton
              label='Delete'
              showLabelAlways
              css={deleteButtonStyles}
              {...deleteConfirmationModalTriggerProps}
              trackingId={calendarEventsTrackingIds.deleteAppointmentEventButton}
            >
              <Icon name='trash' color='error' />
            </IconButton>
            <ConfirmationModal
              title={t('Delete Appointment')}
              message={
                <Text>{t('This appointment will be removed from the schedule. This action cannot be undone.')}</Text>
              }
              {...deleteConfirmationModalProps}
              confirmLabel='Delete'
              onConfirm={() => handleDeleteAppointmentEventTypeSelection()}
              destructive
            />
          </>
        ) : null}
      </>
      <Tray.Actions
        primaryLabel={isSavingAppointment ? t('Saving...') : t('Save')}
        onPrimaryClick={() => submitAppointmentForm()}
        secondaryLabel={t('Cancel')}
        onSecondaryClick={() => cancelButtonHandler()}
        css={{ marginTop: 'auto' }}
        disabledPrimary={!isFormValid || isSavingAppointment || isLoading}
        primaryTrackingId={calendarEventsTrackingIds.saveAppointmentEventButton}
        secondaryTrackingId={calendarEventsTrackingIds.cancelAppointmentEventButton}
      />
    </div>
  );
};

const availableTimeSlotContainer = css({
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  maxHeight: '190px',
  overflowY: 'auto',
});

const fieldContainerStyles = css({
  display: 'grid',
  gridTemplateColumns: 'minmax(auto, 40%) 1fr',
  alignItems: 'center',
  margin: theme.spacing(3, 0),
});

const providerFieldContainerStyles = css({
  display: 'grid',
  gridTemplateColumns: 'minmax(auto, 40%) 1fr',
  alignItems: 'center',
  margin: theme.spacing(3, 0),
});

const timeFieldContainerStyles = css({
  alignItems: 'flex-start',
});

const timeButtonStyles = css({
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
  padding: theme.spacing(0.5),
  border: `1px solid ${theme.colors.neutral50}`,
  borderRadius: theme.borderRadius.small,
});

const deleteButtonStyles = css({
  position: 'absolute',
  bottom: theme.spacing(4),
  width: 'auto',
  height: theme.spacing(4),
  border: 'none',
  fontWeight: 'bold',
  color: theme.colors.critical50,
  backgroundColor: 'transparent',
});

const selectedTimeButtonStyles = css({
  border: `1px solid ${theme.colors.primary50}`,
  backgroundColor: theme.colors.primary10,
  color: theme.colors.primary60,
  display: 'flex',
  alignItems: 'center',
});

const xIconStyles = css({
  padding: theme.spacing(0.5),
  border: 'none',
  backgroundColor: theme.colors.white,
  borderRadius: theme.borderRadius.full,
  color: theme.colors.neutral90,
  marginLeft: theme.spacing(1),
});

const newAppointmentFormContainerStyles = () =>
  css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    overflowY: 'scroll',
  });
