import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  box-shadow: ${theme.shadows.light};
  padding: ${theme.spacing(1)};
  border-radius: ${theme.borderRadius.small};
  margin: ${theme.spacing(0.1, 0.5, 1)};
`;

export const dragIconStyle = css`
  flex-shrink: 0;
`;
