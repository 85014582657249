import { useEffect } from 'react';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { QuickFillHooks } from '@frontend/api-quick-fill';
import { ScheduleQueries } from '@frontend/api-schedule';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Modal, TextareaField, useForm, useModalControl, useAlert, DropdownField, Chip } from '@frontend/design-system';
import { DropdownOptionContent } from './dropdown-item-content';

export const useQuickFillAction = (personId: string, personLocations: DataSourcesHooks.LocationReference[] = []) => {
  const alerts = useAlert();
  const { t } = useTranslation('schedule-quick-fill-action');
  const { selectedLocationIds, getScopeName } = useAppScopeStore();
  const { triggerProps, modalProps, closeModal } = useModalControl({ onClose: () => reset() });
  const { locationIdWiseQuickFillFeature } = QuickFillHooks.useQuickFillFeatureDetails();

  const isSingleLocation = selectedLocationIds.length === 1;
  const personLocationIds = personLocations.map((location) => location.locationId);

  // For single location, we need to explicitly set it to dropdown location ids because personLocationIds is giving [] due to listAccess endpoint returning empty array.
  // @TODO: Remove isSingleLocation check once listAccess endpoint is updated.
  const dropdownLocationIds = isSingleLocation
    ? selectedLocationIds
    : personLocationIds.filter((locationId) => selectedLocationIds.includes(locationId));

  const validLocationIds = dropdownLocationIds.filter((locationId) => locationIdWiseQuickFillFeature[locationId]);

  const { mutateAsync, isLoading } = ScheduleQueries.useMutateAddQuickFillRecipient();

  const { formProps, isComplete, getFieldProps, reset, seedValues, values } = useForm({
    fields: {
      locationId: {
        type: 'dropdown',
        value: validLocationIds.length === 1 ? validLocationIds[0] : '',
        required: true,
      },
      note: {
        type: 'text',
      },
    },
    onSubmit: async ({ note, locationId }) => {
      mutateAsync({
        note,
        personId,
        locationId,
      })
        .then(() => {
          closeModal();
          alerts.success(t('Added to Quick Fill List'));
        })
        .catch(() => {
          alerts.error(t('Error Adding to Quick Fill List'));
        });
    },
  });

  useEffect(() => {
    if (isSingleLocation) {
      seedValues({ ...values, locationId: selectedLocationIds[0] });
    }
  }, [selectedLocationIds]);

  return {
    triggerProps: {
      ...triggerProps,
    },
    Modal: (
      <Modal {...modalProps}>
        <Modal.Header>{t('Add To Quick Fill List')}</Modal.Header>
        <Modal.Body>
          <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2), marginTop: theme.spacing(2) }}>
            {!isSingleLocation &&
              (dropdownLocationIds.length === 1 ? (
                <Chip.Location variant='primaryDark' maxWidth={'fit-content'}>
                  {getScopeName(dropdownLocationIds[0])}
                </Chip.Location>
              ) : (
                dropdownLocationIds.length > 1 && (
                  <DropdownField
                    {...getFieldProps('locationId')}
                    label={t('Location')}
                    startAdornment={<Icon name='location' size={16} css={{ marginRight: theme.spacing(1) }} />}
                  >
                    {dropdownLocationIds?.map((locationId: string) => {
                      const hasQuickFillAccess = locationIdWiseQuickFillFeature[locationId];
                      return (
                        <DropdownField.Option key={locationId} value={locationId} disabled={!hasQuickFillAccess}>
                          <DropdownOptionContent locationId={locationId} hasQuickFillAccess={hasQuickFillAccess} />
                        </DropdownField.Option>
                      );
                    })}
                  </DropdownField>
                )
              ))}
            <TextareaField {...getFieldProps('note')} label={t('Patient notes (optional)')} />
          </div>
        </Modal.Body>
        <Modal.Footer
          primary={{
            label: t('Add'),
            loading: isLoading,
            disabled: !isComplete,
            onClick: (e) => {
              if (e) formProps.onSubmit(e);
            },
          }}
          secondary={{
            label: t('Cancel'),
            onClick: closeModal,
          }}
        />
      </Modal>
    ),
  };
};
