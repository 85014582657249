import {
  CallRoute,
  Edge,
  Node,
  PhoneHoursExpansion,
} from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/callroute_service.pb';
import { ScheduleType } from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/phone_hours.pb';
import { cloneDeep } from 'lodash-es';
import get from 'lodash-es/get';
import set from 'lodash-es/set';
import { associativeNodes, extendableNodes, NodeType, NodeTypes } from '@frontend/node-flow';
import { genUUIDV4 } from '@frontend/string';
import { PhoneNumber } from '../../views/settings/types';
import { CallObjectState } from './call-route-details/add-step-panel/add-step-panel';
import { NodeAction, NodeActionType } from './types';

//  The phone numbers that are not allocated to any call route for a specific tenant.
export const getUnallocatedPhoneNumbers = (
  allPhoneNumbers: PhoneNumber[],
  callRoutes: CallRoute[],
  tenantId: string
) => {
  if (!allPhoneNumbers || !callRoutes || !tenantId) {
    return [];
  }

  // Phone number ids for all phone numbers that currently used in call routes.
  const allocatedPhoneNumbersIds = (callRoutes ?? []).reduce((acc, route) => {
    return [...acc, ...(route.phoneNumberIds ?? [])];
  }, [] as string[]);

  const unallocatedPhoneNumbers = allPhoneNumbers.filter(
    (phoneNumber) => !allocatedPhoneNumbersIds.includes(phoneNumber.id) && phoneNumber.tenantId === tenantId
  );

  return unallocatedPhoneNumbers;
};

/**
 * Helper function that will retrieve the first ancestor node of type `PhoneTree` for a given node ID.
 * To find an ancestor phone tree node, we need to traverse the callroute edges starting from the current
 * node as the target and work our way up to the root node. If we encounter a node with a type of phone
 * tree then return it.
 * NOTE: if the node is a phone tree node, it is not considered an ancestor of itself.
 *
 * @param nodeId - The ID of the node for which to find the ancestor.
 * @param nodes - An array of all nodes.
 * @param edges - An array of all edges connecting the nodes.
 * @returns The ancestor node of type `PhoneTree`, or `undefined` if not found.
 */
export const findPhoneTreeAncestor = (
  nodeId: string,
  nodes: Node[],
  edges: Edge[],
  isInitialCall = true
): Node | undefined => {
  const node = nodes.find((n) => n.id === nodeId);
  if (!node) {
    return undefined;
  }

  // Return undefined if the initial node is a PhoneTree node
  if (isInitialCall && node.type === NodeType.PhoneTree) {
    return undefined;
  }

  const edge = edges.find((e) => e.targetId === nodeId);
  if (!edge) {
    return undefined;
  }

  if (node.type === NodeType.PhoneTree) {
    return node;
  }

  // Pass false for subsequent recursive calls to indicate they're not the initial call
  return findPhoneTreeAncestor(edge.sourceId, nodes, edges, false);
};

/**
 * Helper function used to determine if a node is a descendent of a phone tree node.
 * NOTE: if the node is a phone tree node, it is not considered a descendent of itself.
 *
 * @param nodeId The id of the node to be considered if it is a descendent of a phone tree node.
 * @param nodes The nodes in the call route.
 * @param edges The edges in the call route.
 * @returns
 */
export const isPhoneTreeDescendant = (nodeId: string, nodes: Node[], edges: Edge[]): boolean =>
  !!findPhoneTreeAncestor(nodeId, nodes, edges);

/**
 * Helper function that will take a nodeId and return all the ancestors of that node that are phone tree nodes.
 * Recursively finds all ancestor phone tree nodes of a given node in a call route graph.
 * NOTE: The highest ancestor node is not included in the returned array (even if it is a phone tree node since the
 * highest node should be a start node).
 *
 * @param nodeId - The ID of the node for which to find phone tree ancestors.
 * @param nodes - An array of all nodes in the call route graph.
 * @param edges - An array of all edges representing connections between nodes in the call route graph.
 * @returns An array of ancestor nodes of type NodeType.PhoneTree
 */
export const findAllPhoneTreeAncestors = (nodeId: string, nodes: Node[], edges: Edge[]): Node[] => {
  const node = nodes.find((n) => n.id === nodeId);
  if (!node) {
    return [];
  }

  const edge = edges.find((e) => e.targetId === nodeId);
  if (!edge) {
    return [];
  }

  const ancestors = findAllPhoneTreeAncestors(edge.sourceId, nodes, edges);

  if (node.type === NodeType.PhoneTree) {
    return [...ancestors, node];
  }

  return ancestors;
};

/**
 * Retrieves the child nodes of a given phone tree node that represent dial options.
 * Will return the nodes that are direct children of a phone tree node that are type "TreeOption".
 *
 * @param phoneTreeNodeId - The ID of the phone tree node for which to find child nodes.
 * @param nodes - An array of all nodes in the call route graph.
 * @param edges - An array of all edges connecting nodes in the call route graph.
 * @returns An array of child nodes that are of type `TreeOption` and are connected to the given node.
 */
export const getDialOptionChildren = (phoneTreeNodeId: string, nodes: Node[], edges: Edge[]): Node[] => {
  const nodeEdges = edges.filter((edge) => edge.sourceId === phoneTreeNodeId);
  if (!nodeEdges.length) {
    return [];
  }

  const dialOptions = nodeEdges.reduce<Node[]>((acc, edge) => {
    const childNode = nodes.find((n) => n.id === edge.targetId);
    if (!childNode || childNode.type !== NodeType.TreeOption) {
      return acc;
    }

    return [...acc, childNode];
  }, []);

  return dialOptions;
};

/**
 * Determines if a given node type is a terminal node.
 *
 * A terminal node is defined as a node type that is not extendable (can not add downstream steps to it).
 *
 * @param nodeType - The type of the node to check. If not provided, an error is logged and the function returns false.
 * @returns `true` if the node type is a terminal node, `false` otherwise.
 */
export const isTerminalNode = (nodeType?: string): boolean => {
  if (!nodeType) {
    console.error('Node type is missing');
    return false;
  }

  return !extendableNodes.includes(nodeType);
};

export const isAssociativeNode = (nodeType?: string) => {
  if (!nodeType) {
    console.error('Node type is missing');
    return false;
  }

  return associativeNodes.includes(nodeType);
};

/**
 * Retrieves all descendant nodes of a given node in a graph.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.nodeId - The ID of the node to start from.
 * @param {Node[]} params.nodes - The list of all nodes in the graph.
 * @param {Edge[]} params.edges - The list of all edges in the graph.
 * @param {string[]} [params.omitIds] - Optional list of node IDs to omit them and their descendants from the results.
 * @returns {Node[]} An array of descendant nodes.
 */
export const getDescendantNodes = ({
  nodeId,
  nodes,
  edges,
  omitIds,
}: {
  nodeId: string;
  nodes: Node[];
  edges: Edge[];
  omitIds?: string[];
}): Node[] => {
  const node = nodes.find((n) => n.id === nodeId);
  if (!node) {
    return [];
  }

  const nodeEdges = edges.filter((edge) => edge.sourceId === nodeId);
  if (!nodeEdges.length) {
    return [];
  }

  const descendants = nodeEdges.reduce<Node[]>((acc, edge) => {
    const childNode = nodes.find((n) => n.id === edge.targetId);
    if (!childNode || (omitIds && omitIds.includes(childNode.id))) {
      return acc;
    }

    return [...acc, childNode, ...getDescendantNodes({ nodeId: childNode.id, nodes, edges })];
  }, []);

  return descendants;
};

/**
 * Determines if a node has grandchildren in a graph.
 *
 * Grandchildren are considered to be the children of the children of the node. So this function will
 * return true if the node has at least one child node that itself has at least one child node.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.nodeId - The ID of the node to check for grandchildren.
 * @param {Node[]} params.nodes - The array of nodes in the graph.
 * @param {Edge[]} params.edges - The array of edges in the graph.
 * @returns {boolean} - Returns `true` if the node has grandchildren, otherwise `false`.
 */
export const hasGrandchildren = ({
  nodeId,
  nodes,
  edges,
}: {
  nodeId: string;
  nodes: Node[];
  edges: Edge[];
}): boolean => {
  const node = nodes.find((n) => n.id === nodeId);
  if (!node) {
    return false;
  }

  const nodeEdges = edges.filter((edge) => edge.sourceId === nodeId);
  if (!nodeEdges.length) {
    return false;
  }

  const hasGrandchildren = nodeEdges.some((edge) => {
    const childNode = nodes.find((n) => n.id === edge.targetId);
    if (!childNode) {
      return false;
    }

    return edges.some((e) => e.sourceId === childNode.id);
  });

  return hasGrandchildren;
};

/**
 * Helper function that will return true if the node with the given nodeId has any downstream steps.
 * Downstream steps are considered to be the direct children of the node if the node is a non-associative node.
 * If the node is an associative node, then the downstream steps are considered to be the grandchildren of the node.
 * Since the direct children of an associative node are meta nodes such as dialOptions, "fallback", "open", "closed" and "break" nodes.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.nodeId - The ID of the node to check.
 * @param {Node[]} params.nodes - The list of all nodes.
 * @param {Edge[]} params.edges - The list of all edges.
 * @returns {boolean} - Returns true if the node has downstream steps, otherwise false.
 */
export const hasDownStreamSteps = ({
  nodeId,
  nodes,
  edges,
}: {
  nodeId: string;
  nodes: Node[];
  edges: Edge[];
}): boolean => {
  const node = nodes.find((n) => n.id === nodeId);
  if (!node) {
    console.error('Could not find node');
    return false;
  }

  const isAssociative = isAssociativeNode(node.type);

  let hasDownStreamSteps = false;
  if (isAssociative) {
    hasDownStreamSteps = hasGrandchildren({ nodeId, nodes, edges });
  } else {
    hasDownStreamSteps = getDescendantNodes({ nodeId, nodes, edges }).length > 0;
  }

  return hasDownStreamSteps;
};

/**
 * Determines whether a warning should be shown when editing a node.
 * The states to show a warning are:
 *
 * - non-associative step w/ children --> associative step
 *    The user is trying to change a non-associative node to an associative node and the non-associative node has
 *    downstream steps (grandchildren in this case since the direct children are meta nodes such as dialOptions,
 *    "fallback", "open", "closed" and "break" nodes).
 *
 * - associative step w/ children --> non-associative step
 *    The user is trying to change an associative node to a non-associative node and the associative node has downstream steps.
 *
 * - associative step w/ children --> associative step
 *    The user is trying to change an associative node to another associative node and the original associative node has downstream steps.
 *
 * - non-associative step w/ children --> non-associative step and is terminal
 *    The user is trying to change a non-associative node to another non-associative node but the new step is a terminal step
 *    and the original non-associative node has downstream steps
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.nodeId - The ID of the node being edited.
 * @param {NodeTypes} params.newNodeType - The new type of the node.
 * @param {Node[]} params.nodes - The list of all nodes.
 * @param {Edge[]} params.edges - The list of all edges.
 * @returns {boolean} - Returns `true` if a warning should be shown, otherwise `false`.
 * ```
 */
export const shouldShowWarningForEdit = ({
  nodeId,
  newNodeType,
  nodes,
  edges,
}: {
  nodeId: string;
  newNodeType: NodeTypes;
  nodes: Node[];
  edges: Edge[];
}): boolean => {
  const editingNode = nodes.find((node) => node.id === nodeId);
  if (!editingNode) {
    console.error('Could not find editing node');
    return false;
  }

  if (editingNode.type === newNodeType) {
    return false;
  }

  const isAssociative = isAssociativeNode(editingNode.type);

  const hasChildrenSteps = hasDownStreamSteps({ nodeId: nodeId, nodes, edges });

  const newTypeIsAssociative = isAssociativeNode(newNodeType);
  const newTypeIsTerminal = isTerminalNode(newNodeType);

  if (!isAssociative && hasChildrenSteps && newTypeIsAssociative) {
    return true;
  }

  if (isAssociative && hasChildrenSteps && !newTypeIsAssociative) {
    return true;
  }

  if (isAssociative && hasChildrenSteps && newTypeIsAssociative) {
    return true;
  }

  if (!isAssociative && hasChildrenSteps && !newTypeIsAssociative && newTypeIsTerminal) {
    return true;
  }

  return false;
};

/**
 * Determines whether a warning should be shown when adding a new node to the call routes.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.extendingNodeId - The ID of the node being extended.
 * @param {NodeTypes} params.newNodeType - The type of the new node being added.
 * @param {Node[]} params.nodes - The list of all nodes in the call routes.
 * @param {Edge[]} params.edges - The list of all edges in the call routes.
 * @returns {boolean} - Returns `true` if a warning should be shown, otherwise `false`.
 */
export const shouldShowWarningForAdd = ({
  extendingNodeId,
  newNodeType,
  nodes,
  edges,
}: {
  extendingNodeId: string;
  newNodeType: NodeTypes;
  nodes: Node[];
  edges: Edge[];
}): boolean => {
  const extendingNode = nodes.find((node) => node.id === extendingNodeId);
  if (!extendingNode) {
    console.error('Could not find extending node');
    return false;
  }

  const hasChildrenSteps = hasDownStreamSteps({ nodeId: extendingNodeId, nodes, edges });
  const newTypeIsAssociative = isAssociativeNode(newNodeType);
  const isTerminal = isTerminalNode(newNodeType);

  if (hasChildrenSteps && (newTypeIsAssociative || isTerminal)) {
    return true;
  }

  return false;
};

/**
 * Determines whether a warning should be shown when deleting a node.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.deletingNodeId - The ID of the node being deleted.
 * @param {Node[]} params.nodes - The list of all nodes.
 * @param {Edge[]} params.edges - The list of all edges.
 * @returns {boolean} - Returns `true` if a warning should be shown, otherwise `false`.
 */
export const shouldShowWarningForDelete = ({
  deletingNodeId,
  nodes,
  edges,
}: {
  deletingNodeId: string;
  nodes: Node[];
  edges: Edge[];
}): boolean => {
  const deletingNode = nodes.find((node) => node.id === deletingNodeId);
  if (!deletingNode) {
    console.error('Could not find deleting node');
    return false;
  }

  const withDownStreamSteps = hasDownStreamSteps({ nodeId: deletingNodeId, nodes, edges });
  const isAssociative = isAssociativeNode(deletingNode.type);

  if (isAssociative && withDownStreamSteps) {
    return true;
  }

  return false;
};

/**
 * Determines whether a warning should be shown based on the provided action type and node details.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.nodeId - The ID of the node being acted upon.
 * @param {NodeTypes} [params.newNodeType] - The new type of the node, if applicable.
 * @param {Node[]} params.nodes - The list of all nodes.
 * @param {Edge[]} params.edges - The list of all edges.
 * @param {NodeActionType} params.actionType - The type of action being performed on the node.
 * @returns {boolean} - Returns `true` if a warning should be shown, otherwise `false`.
 */
export const shouldShowWarning = ({
  nodeId,
  newNodeType,
  nodes,
  edges,
  actionType,
}: {
  nodeId: string;
  newNodeType?: NodeTypes;
  nodes: Node[];
  edges: Edge[];
  actionType: NodeActionType;
}): boolean => {
  if (actionType === NodeAction.Edit && newNodeType) {
    return shouldShowWarningForEdit({ nodeId: nodeId, newNodeType, nodes, edges });
  } else if (actionType === NodeAction.Add && newNodeType) {
    return shouldShowWarningForAdd({ extendingNodeId: nodeId, newNodeType, nodes, edges });
  } else if (actionType === NodeAction.Delete) {
    return shouldShowWarningForDelete({ deletingNodeId: nodeId, nodes, edges });
  }

  return false;
};

/**
 * Helper function that will return the nodes and edges that need to be added to the call route in order to add a phone
 * tree step. Will remove all nodes and edges that are descendants of the node that the user wants to add the phone tree
 * step to and add the corresponding phone tree node along with the dial options and fallback nodes (and their
 * connecting edges).
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.extendingNodeId - The ID of the node to which a step is being added.
 * @param {Node[]} params.nodes - The current list of nodes.
 * @param {Edge[]} params.edges - The current list of edges.
 * @param {CallObjectState} params.data - The data containing type, callObject, and dialOptions.
 * @param {Object} params.data.callObject - The call object data associated with the new phone tree node.
 * @param {string[]} [params.data.dialOptions=[]] - The dial options for the new phone tree node.
 *
 * @returns {Object} An object containing the updated nodes and edges.
 * @returns {Node[]} returns.nodes - The updated list of nodes.
 * @returns {Edge[]} returns.edges - The updated list of edges.
 */
export const getPhoneTreeNodesForAdd = ({
  extendingNodeId,
  nodes,
  edges,
  data: { type, callObject, dialOptions = [] },
}: {
  extendingNodeId: string;
  nodes: Node[];
  edges: Edge[];
  data: CallObjectState;
}) => {
  if (!type || !callObject) {
    console.error('Missing type or callObject');
    return {
      nodes,
      edges,
    };
  }

  // Get the node that the user wants to add a step to.
  const extendingNode = nodes.find((node) => node.id === extendingNodeId);
  if (!extendingNode) {
    console.error('Could not find extending node');
    return {
      nodes,
      edges,
    };
  }

  const descendants = getDescendantNodes({ nodeId: extendingNodeId ?? '', nodes, edges });
  const descendantNodeIds = descendants.map((node) => node.id);

  // Remove the descendants of the extending node.
  const filteredNodes = nodes.filter((node) => !descendantNodeIds.includes(node.id));
  // Remove the edges that are connected to the descendants of the extending node and also remove the edges that currently connect
  // to the extending node where the extending node is the source.
  const filteredEdges = edges.filter(
    (edge) =>
      edge.sourceId !== extendingNodeId &&
      !descendantNodeIds.includes(edge.sourceId) &&
      !descendantNodeIds.includes(edge.targetId)
  );

  const newPhoneTreeNode = {
    id: genUUIDV4(),
    type,
    callObject,
    isPhoneTreeDescendent: false,
  };

  // Create the new edge that connects the new node to the parent node.
  const newEdgeToConnectPhoneTree = {
    id: `${extendingNodeId}:${newPhoneTreeNode.id}`,
    sourceId: extendingNodeId ?? '',
    targetId: newPhoneTreeNode.id,
    type: 'basic',
  };

  // Create new nodes for every dial option. Create an edge that connects the new node to the new phone tree node.
  const { newNodes, newEdges } = dialOptions.reduce<{ newNodes: Node[]; newEdges: Edge[] }>(
    (acc, dialOption) => {
      const { newNodes, newEdges } = acc;
      const { node, edge } = getNewNodeAndEdge({
        nodeType: NodeType.TreeOption,
        parentNodeId: newPhoneTreeNode.id,
        name: dialOption,
        isPhoneTreeDescendent: true,
      });

      return {
        newNodes: [...newNodes, node],
        newEdges: [...newEdges, edge],
      };
    },
    { newNodes: [], newEdges: [] }
  );

  // Add one extra "Fallback" node connected to the phone tree (Phone tree nodes should always have a
  // connected fallback node).
  const { node: newFallbackNode, edge: newFallbackEdge } = getNewNodeAndEdge({
    nodeType: NodeType.Fallback,
    parentNodeId: newPhoneTreeNode.id,
    name: 'Fallback',
    isPhoneTreeDescendent: true,
  });

  // Add the new fallback node its connecting edge to the new nodes and edges.
  newNodes.push(newFallbackNode);
  newEdges.push(newFallbackEdge);

  // Combine the new nodes and edges with the existing nodes and edges. The edges will be added after the index of the edge
  // that has the targetId of the parent node in order for the react-flow to position it in the correct order.
  const combinedNodes = [...filteredNodes, newPhoneTreeNode, ...newNodes];
  const combinedEdges = [...filteredEdges, ...newEdges, newEdgeToConnectPhoneTree];

  return sortEdgesDepthFirst({ nodes: combinedNodes, edges: combinedEdges });
};

/**
 * Helper function used for getting the updated nodes and edges when editing a phone tree node.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.editingNodeId - The ID of the node being edited.
 * @param {Node[]} params.nodes - The current list of nodes in the phone tree.
 * @param {Edge[]} params.edges - The current list of edges in the phone tree.
 * @param {CallObjectState} params.data - The data containing type, callObject, and dialOptions.
 * @param {Object} params.data.callObject - The call object data associated with the node.
 * @param {string[]} [params.data.dialOptions=[]] - The dial options available for the node.
 *
 * @returns {Object} An object containing the updated nodes and edges.
 * @returns {Node[]} returns.nodes - The updated list of nodes.
 * @returns {Edge[]} returns.edges - The updated list of edges.
 */
export const getPhoneTreeNodesForEdit = ({
  editingNodeId,
  nodes,
  edges,
  data: { type, callObject, dialOptions = [] },
}: {
  editingNodeId: string;
  nodes: Node[];
  edges: Edge[];
  data: CallObjectState;
}) => {
  if (!type || !callObject) {
    console.error('Missing type or callObject');
    return {
      nodes,
      edges,
    };
  }

  // Get the node that the user wants to edit.
  const editingNode = nodes.find((node) => node.id === editingNodeId);
  if (!editingNode) {
    console.error('Could not find editing node');
    return {
      nodes,
      edges,
    };
  }

  // Get all the current nodes that are direct children of the editing node who are still part of the dialOptions.
  const descendantsToKeep = edges
    .filter((edge) => edge.sourceId === editingNodeId)
    .map((edge) =>
      nodes.find(
        (node) =>
          node.id === edge.targetId &&
          (dialOptions?.includes(node.callObject.primitiveName) || node.callObject.primitiveName === 'Fallback')
      )
    )
    .filter(Boolean);

  // Get all descendants of the editing node that are no longer part of the dialOptions.
  const descendantsToRemove = getDescendantNodes({
    nodeId: editingNodeId ?? '',
    nodes,
    edges,
    omitIds: descendantsToKeep.map((node) => node.id),
  });

  // Remove the descendants of the editing node if they are no longer part of the dialOptions.
  const filteredNodes = nodes.filter(
    (node) =>
      !descendantsToRemove.some(
        (descendant) => descendant.id === node.id && !dialOptions?.includes(descendant.callObject.primitiveName)
      )
  );

  // Remove the edges that are connected to the descendants of the editing node that are no longer part of the dialOptions.
  const filteredEdges = edges.filter(
    (edge) =>
      !descendantsToRemove.some((descendant) => edge.sourceId === descendant.id || edge.targetId === descendant.id)
  );

  const newDialOptions = dialOptions.filter(
    (dialOption) => !descendantsToKeep.some((node) => node.callObject.primitiveName === dialOption)
  );

  // Create new nodes for every new dial option. Create an edge that connects the new node to the new phone tree node.
  const { newNodes, newEdges } = newDialOptions.reduce<{ newNodes: Node[]; newEdges: Edge[] }>(
    (acc, dialOption) => {
      const { newNodes, newEdges } = acc;
      const { node, edge } = getNewNodeAndEdge({
        nodeType: NodeType.TreeOption,
        parentNodeId: editingNode.id,
        name: dialOption,
        isPhoneTreeDescendent: true,
      });

      return {
        newNodes: [...newNodes, node],
        newEdges: [...newEdges, edge],
      };
    },
    { newNodes: [], newEdges: [] }
  );

  if (!descendantsToKeep.some((node) => node.callObject.primitiveName === 'Fallback')) {
    // Add one extra "Fallback" node connected to the phone tree (Phone tree nodes should always have a
    // connected fallback node).
    const { node: newFallbackNode, edge: newFallbackEdge } = getNewNodeAndEdge({
      nodeType: NodeType.Fallback,
      parentNodeId: editingNode.id,
      name: 'Fallback',
      isPhoneTreeDescendent: true,
    });

    // Add the new fallback node its connecting edge to the new nodes and edges.
    newNodes.push(newFallbackNode);
    newEdges.push(newFallbackEdge);
  }

  // Combine the new nodes and edges with the existing nodes and edges. The edges will be added after the index of the edge
  // that has the targetId of the parent node in order for the react-flow to position it in the correct order.
  const combinedNodes = [...filteredNodes, ...newNodes];

  const nodeIndex = combinedNodes.findIndex((node) => node.id === editingNodeId);
  if (nodeIndex === -1) {
    console.error('Could not find editing node');
  }
  const newNode = {
    id: editingNodeId,
    type,
    callObject,
    isPhoneTreeDescendent: false,
  };
  combinedNodes[nodeIndex] = newNode;

  const combinedEdges = [...filteredEdges, ...newEdges];

  return sortEdgesDepthFirst({ nodes: combinedNodes, edges: combinedEdges });
};

/**
 * Helper function that will return the nodes and edges that need to be added to the call route in order to add a phone hours step.
 * Will remove all nodes and edges that are descendants of the node that the user wants to add the phone hours step to and add the
 * corresponding phone hours node along with the "closed", "open" and "break" nodes (and their connecting edges).
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.extendingNodeId - The ID of the node to which a step is being added.
 * @param {Node[]} params.nodes - The current list of nodes.
 * @param {Edge[]} params.edges - The current list of edges.
 * @param {Object} params.data - The data object containing the callObject.
 * @param {CallObjectState} params.data.callObject - The call object state.
 *
 * @returns {Object} An object containing the updated nodes and edges.
 * @returns {Node[]} returns.nodes - The updated list of nodes.
 * @returns {Edge[]} returns.edges - The updated list of edges.
 */
export const getPhoneHoursNodesForAdd = ({
  extendingNodeId,
  nodes,
  edges,
  data: { type, callObject },
}: {
  extendingNodeId: string;
  nodes: Node[];
  edges: Edge[];
  data: CallObjectState;
}) => {
  if (!type || !callObject) {
    console.error('Missing type or callObject');
    return {
      nodes,
      edges,
    };
  }

  // Get the node that the user wants to add a step to.
  const extendingNode = nodes.find((node) => node.id === extendingNodeId);
  if (!extendingNode) {
    console.error('Could not find extending node');
    return {
      nodes,
      edges,
    };
  }

  const descendants = getDescendantNodes({ nodeId: extendingNodeId ?? '', nodes, edges });
  const descendantNodeIds = descendants.map((node) => node.id);

  // Remove the descendants of the extending node.
  const filteredNodes = nodes.filter((node) => !descendantNodeIds.includes(node.id));
  // Remove the edges that are connected to the descendants of the extending node and also remove the edges that currently connect
  // to the extending node where the extending node is the source.
  const filteredEdges = edges.filter(
    (edge) =>
      edge.sourceId !== extendingNodeId &&
      !descendantNodeIds.includes(edge.sourceId) &&
      !descendantNodeIds.includes(edge.targetId)
  );

  // Create the new phone hours node.
  const newAssociativeNode = {
    id: genUUIDV4(),
    type,
    callObject,
    isPhoneTreeDescendent: false,
  };

  // Create the new edge that connects the new node to the parent node.
  const newEdgeToConnectPhoneTree = {
    id: `${extendingNodeId}:${newAssociativeNode.id}`,
    sourceId: extendingNodeId ?? '',
    targetId: newAssociativeNode.id,
    type: 'basic',
  };

  // @ts-expect-error ignore this until we get better types
  const phoneHoursExpansionData: PhoneHoursExpansion = callObject?.phoneHoursExpansion;
  // Create the new nodes for every break. Create an edge that connects the new nodes to the new phone hours node.
  const breaks = phoneHoursExpansionData?.phoneHours?.filter((hours) => hours.type === ScheduleType.BREAK) ?? [];
  const { newNodes, newEdges } = breaks.reduce<{ newNodes: Node[]; newEdges: Edge[] }>(
    (acc, brk) => {
      const { newNodes, newEdges } = acc;
      const { node, edge } = getNewNodeAndEdge({
        nodeType: NodeType.BreakPhoneHours,
        parentNodeId: newAssociativeNode.id,
        name: brk.name,
      });

      return {
        newNodes: [...newNodes, node],
        newEdges: [...newEdges, edge],
      };
    },
    { newNodes: [], newEdges: [] }
  );

  // Add one extra "Open" node connected to the phone hours node (Phone hours nodes should always have a
  // connected Open node).
  const { node: newOpenHoursNode, edge: newOpenHoursEdge } = getNewNodeAndEdge({
    nodeType: NodeType.OpenPhoneHours,
    parentNodeId: newAssociativeNode.id,
    name: 'Open',
  });

  // Add the new Open Hours node and its connecting edge to the new nodes and edges.
  newNodes.push(newOpenHoursNode);
  newEdges.push(newOpenHoursEdge);

  // Add one extra "Closed" node connected to the phone hours node (Phone hours nodes should always have a
  // connected Closed node).
  const { node: newClosedHoursNode, edge: newClosedHoursEdge } = getNewNodeAndEdge({
    nodeType: NodeType.ClosedPhoneHours,
    parentNodeId: newAssociativeNode.id,
    name: 'Closed',
  });

  // Add the new Open Hours node and its connecting edge to the new nodes and edges.
  newNodes.push(newClosedHoursNode);
  newEdges.push(newClosedHoursEdge);

  // Combine the new nodes and edges with the existing nodes and edges. The edges will be added after the index of the edge
  // that has the targetId of the parent node in order for the react-flow to position it in the correct order.
  const combinedNodes = [...filteredNodes, newAssociativeNode, ...newNodes];
  const combinedEdges = [...filteredEdges, ...newEdges, newEdgeToConnectPhoneTree];

  return sortEdgesDepthFirst({ nodes: combinedNodes, edges: combinedEdges });
};

/**
 * Helper function used for getting the updated nodes and edges when editing a phone hours node.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.editingNodeId - The ID of the node being edited.
 * @param {Node[]} params.nodes - The current list of nodes.
 * @param {Edge[]} params.edges - The current list of edges.
 * @param {Object} params.data - Additional data for the function.
 * @param {string} params.data.type - The type of the call object.
 * @param {CallObjectState} params.data.callObject - The call object state.
 *
 * @returns {Object} An object containing the updated nodes and edges.
 * @returns {Node[]} returns.nodes - The updated list of nodes.
 * @returns {Edge[]} returns.edges - The updated list of edges.
 */
export const getPhoneHoursNodesForEdit = ({
  editingNodeId,
  nodes,
  edges,
  data: { type, callObject },
}: {
  editingNodeId: string;
  nodes: Node[];
  edges: Edge[];
  data: CallObjectState;
}) => {
  if (!type || !callObject) {
    console.error('Missing type or callObject');
    return {
      nodes,
      edges,
    };
  }

  // Get the node that the user wants to edit
  const editingNode = nodes.find((node) => node.id === editingNodeId);
  if (!editingNode) {
    console.error('Could not find editing node');
    return {
      nodes,
      edges,
    };
  }

  // PT-TODO: update this
  // @ts-expect-error ignore this until we get better types
  const phoneHoursExpansionData: PhoneHoursExpansion = callObject?.phoneHoursExpansion;
  const phoneHourBreaks = phoneHoursExpansionData?.phoneHours
    ?.filter((hours) => hours.type === ScheduleType.BREAK)
    .map((hours) => hours.name);

  // Get all the current nodes that are direct children of the editing node that are still part of the phone hours.
  const descendantsToKeep = edges
    .filter((edge) => edge.sourceId === editingNodeId)
    .map((edge) =>
      nodes.find(
        (node) =>
          node.id === edge.targetId &&
          (node.type === NodeType.ClosedPhoneHours ||
            node.type === NodeType.OpenPhoneHours ||
            (node.type === NodeType.BreakPhoneHours && phoneHourBreaks.includes(node.callObject.primitiveName)))
      )
    )
    .filter(Boolean);

  // Get all descendants of the editing node that are no longer part of the phone hours.
  const descendantsToRemove = getDescendantNodes({
    nodeId: editingNodeId ?? '',
    nodes,
    edges,
    omitIds: descendantsToKeep.map((node) => node.id),
  });

  const descendantsToRemoveIds = descendantsToRemove.map((node) => node.id);

  // Remove the descendants of the editing node if they are no longer part of the phone hours.
  const filteredNodes = nodes.filter((node) => !descendantsToRemoveIds.includes(node.id));
  // Remove the edges that are connected to the descendants of the editing node that are no longer part of the phone hours.
  const filteredEdges = edges.filter(
    (edge) => !descendantsToRemoveIds.includes(edge.sourceId) && !descendantsToRemoveIds.includes(edge.targetId)
  );

  // The breaks for which there are currently nodes in the call route.
  const currentBreaks = descendantsToKeep
    .filter((node) => node.type === NodeType.BreakPhoneHours)
    .map((node) => node.callObject.primitiveName);
  // The breaks that are part of the phone hours expansion data but are not currently nodes in the call route.
  const newBreaks = phoneHoursExpansionData?.phoneHours
    ?.filter((hours) => hours.type === ScheduleType.BREAK && !currentBreaks.includes(hours.name))
    .map((hours) => hours.name);

  // Create the new nodes for every break. Create an edge that connects the new nodes to the new phone hours node.
  const { newNodes, newEdges } = newBreaks.reduce<{ newNodes: Node[]; newEdges: Edge[] }>(
    (acc, breakName) => {
      const { newNodes, newEdges } = acc;
      const { node, edge } = getNewNodeAndEdge({
        nodeType: NodeType.BreakPhoneHours,
        parentNodeId: editingNodeId,
        name: breakName,
      });

      return {
        newNodes: [...newNodes, node],
        newEdges: [...newEdges, edge],
      };
    },
    { newNodes: [], newEdges: [] }
  );

  if (!descendantsToKeep.some((node) => node.type === NodeType.OpenPhoneHours)) {
    // Add one extra "Open" node connected to the phone hours node (Phone hours nodes should always have a
    // connected Open node).
    const { node: newOpenHoursNode, edge: newOpenHoursEdge } = getNewNodeAndEdge({
      nodeType: NodeType.OpenPhoneHours,
      parentNodeId: editingNodeId,
      name: 'Open',
    });

    // Add the new Open Hours node and its connecting edge to the new nodes and edges.
    newNodes.push(newOpenHoursNode);
    newEdges.push(newOpenHoursEdge);
  }

  if (!descendantsToKeep.some((node) => node.type === NodeType.ClosedPhoneHours)) {
    // Add one extra "Closed" node connected to the phone hours node (Phone hours nodes should always have a
    // connected Closed node).
    const { node: newClosedHoursNode, edge: newClosedHoursEdge } = getNewNodeAndEdge({
      nodeType: NodeType.ClosedPhoneHours,
      parentNodeId: editingNodeId,
      name: 'Closed',
    });

    // Add the new Open Hours node and its connecting edge to the new nodes and edges.
    newNodes.push(newClosedHoursNode);
    newEdges.push(newClosedHoursEdge);
  }

  // Combine the new nodes and edges with the existing nodes and edges. The edges will be added after the index of the edge
  // that has the targetId of the parent node in order for the react-flow to position it in the correct order.
  const combinedNodes = [...filteredNodes, ...newNodes];

  const nodeIndex = combinedNodes.findIndex((node) => node.id === editingNodeId);
  if (nodeIndex === -1) {
    console.error('Could not find editing node');
  }
  const newNode = {
    id: editingNodeId,
    type,
    callObject,
    isPhoneTreeDescendent: false,
  };
  combinedNodes[nodeIndex] = newNode;

  const combinedEdges = [...filteredEdges, ...newEdges];

  return sortEdgesDepthFirst({ nodes: combinedNodes, edges: combinedEdges });
};

/**
 * Helper function used to get the updated nodes and edges for a non-associative edit operation.
 * NOTE: An associative node is a node that has downstream dependencies (e.g.phone tree and phone hours nodes)
 * so non-associative nodes are nodes that do not have downstream dependencies.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.processingNodeId - The ID of the node being processed.
 * @param {Node[]} params.nodes - The array of nodes.
 * @param {Edge[]} params.edges - The array of edges.
 * @param {Object} params.data - Additional data for the function.
 * @param {string} params.data.type - The type of the node.
 * @param {CallObjectState} params.data.callObject - The call object state.
 *
 * @returns {Object} An object containing the updated nodes and edges.
 * @returns {Node[]} returns.nodes - The updated array of nodes.
 * @returns {Edge[]} returns.edges - The updated array of edges.
 */
export const getNodesForNonAssociativeEdit = ({
  processingNodeId,
  nodes,
  edges,
  data: { type, callObject },
}: {
  processingNodeId: string;
  nodes: Node[];
  edges: Edge[];
  data: CallObjectState;
}) => {
  if (!type || !callObject) {
    console.error('Missing type or callObject');
    return {
      nodes,
      edges,
    };
  }

  const editingNode = nodes.find((node) => node.id === processingNodeId);
  if (!editingNode) {
    console.error('Could not find editing node');
    return { nodes, edges };
  }
  const oldTypeIsAssociative = isAssociativeNode(editingNode.type);

  const index = nodes.findIndex((node) => node.id === processingNodeId);
  if (index === -1) {
    console.error('Could not find editing node');
    return { nodes, edges };
  }

  let newEdges = [...edges];
  let newNodes = [...nodes];
  const newNode = {
    id: processingNodeId,
    type,
    callObject,
    isPhoneTreeDescendent: isPhoneTreeDescendant(processingNodeId ?? '', nodes, edges),
  };
  newNodes[index] = newNode;

  const newTypeIsTerminal = isTerminalNode(type);

  // if the old type was an associative node or if the new type is a terminal node, then we need to delete all
  // of the descendant nodes of the node that the user is editing and then add the new node.
  if (oldTypeIsAssociative || newTypeIsTerminal) {
    const descendants = getDescendantNodes({
      nodeId: processingNodeId ?? '',
      nodes: newNodes,
      edges: newEdges,
    });
    const descendantNodeIds = descendants.map((node) => node.id);

    // Filter out all descendant nodes from the nodes array.
    newNodes = newNodes.filter((node) => !descendantNodeIds.includes(node.id));

    // Filter out all edges that connect any descendant nodes of the nodeToDelete and also the edge that connects
    // the parent node to the nodeToDelete.
    newEdges = newEdges.filter(
      (edge) => !descendantNodeIds.includes(edge.sourceId) && !descendantNodeIds.includes(edge.targetId)
    );
  }

  return sortEdgesDepthFirst({ nodes: newNodes, edges: newEdges });
};

/**
 * Helper function used to get the updated nodes and edges for a non-associative add operation in a call graph.
 * NOTE: An associative node is a node that has downstream dependencies (e.g.phone tree and phone hours nodes)
 * so non-associative nodes are nodes that do not have downstream dependencies.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.processingNodeId - The ID of the node being processed.
 * @param {Node[]} params.nodes - The current list of nodes.
 * @param {Edge[]} params.edges - The current list of edges.
 * @param {CallObjectState} params.data - The data containing the type and call object.
 * @returns {Object} An object containing the updated nodes and edges.
 * @returns {Node[]} returns.nodes - The updated list of nodes.
 * @returns {Edge[]} returns.edges - The updated list of edges.
 */
export const getNodesForNonAssociativeAdd = ({
  processingNodeId,
  nodes,
  edges,
  data: { type, callObject },
}: {
  processingNodeId: string;
  nodes: Node[];
  edges: Edge[];
  data: CallObjectState;
}) => {
  if (!type || !callObject) {
    console.error('Missing type or callObject');
    return {
      nodes,
      edges,
    };
  }

  // Check to make sure that the node that the user wants to add a step to exists.
  const extendingNode = nodes.find((node) => node.id === processingNodeId);
  if (!extendingNode) {
    console.error('Could not find extending node');
    return {
      nodes,
      edges,
    };
  }

  // Create the new node with a random id and the type of the node that the user selected.
  const newNodeId = genUUIDV4();
  const newNode = {
    id: newNodeId,
    type,
    callObject,
    isPhoneTreeDescendent: isPhoneTreeDescendant(processingNodeId ?? '', nodes, edges),
  };

  // Check if the node that the user wants to add a step to is a leaf node. If it is a leaf node, then we need to
  // create a new edge that connects the new node to the parent node.
  // If it is not a leaf node, then we need to create a new edge that connects the new node to the
  // first child node of the parent node and update the parent node's edge's targetId to target the new node.
  const isLeafNode = edges?.every((edge) => edge.sourceId !== processingNodeId);

  if (isLeafNode) {
    // Create the new edge that connects the new node to the parent node.
    const newEdge = {
      id: `${processingNodeId}:${newNodeId}`,
      sourceId: processingNodeId ?? '',
      targetId: newNodeId,
      type: 'basic',
    };

    const newNodes = [...nodes, newNode];
    const newEdges = [...edges, newEdge];

    return sortEdgesDepthFirst({ nodes: newNodes, edges: newEdges });
  } else {
    // Get the edge that is currently connected to the parent node that will be connected to the new node.
    const parentEdge = edges?.find((edge) => edge.sourceId === processingNodeId);
    if (!parentEdge) {
      console.error('Could not find edge with sourceId of extending node: PC-83je04l');
      return { nodes, edges };
    }

    // NOTE: Leaf nodes will always be adding a new node and never editing an existing node.
    let newNodes = [...nodes, newNode];
    let newEdges = [...edges];

    const isTerminal = isTerminalNode(type);
    if (isTerminal) {
      // Check if the new node is a terminal node, if so, then we need to remove all of the descendant nodes of
      // the new node.

      // Get all descendant nodes of the nodeToDelete.
      const descendantNodes = getDescendantNodes({ nodeId: processingNodeId, nodes: newNodes, edges: newEdges });
      const descendantNodeIds = descendantNodes.map((node) => node.id);

      // Filter out all descendant nodes from the nodes array.
      newNodes = newNodes.filter((node) => !descendantNodeIds.includes(node.id));
      // Filter out all edges that connect any descendant nodes of the new node
      newEdges = newEdges.filter((edge) => !descendantNodeIds.includes(edge.sourceId));
    } else {
      // If not adding a terminal node, then we need to make a connection from the new node to the first child node
      // of the parent node.

      // New connection from the new node to the parent node's old target
      const targetEdge = {
        id: `${newNodeId}->${parentEdge.targetId}`,
        sourceId: newNodeId,
        targetId: parentEdge.targetId,
        type: 'basic',
      };

      newEdges.push(targetEdge);
    }

    // Update the parent node's edge to target the new node.
    const index = edges.findIndex((edge) => edge.sourceId === processingNodeId);
    newEdges[index] = { ...newEdges[index], targetId: newNodeId };

    return sortEdgesDepthFirst({ nodes: newNodes, edges: newEdges });
  }
};

/**
 * Removes a node and its associated edges from the graph. If the node is associative,
 * it also removes all descendant nodes and their edges. If the node is not associative,
 * it updates the edges to maintain the graph structure.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.nodeId - The ID of the node to delete.
 * @param {Node[]} params.nodes - The array of nodes in the graph.
 * @param {Edge[]} params.edges - The array of edges in the graph.
 * @returns {Object} - An object containing the updated nodes and edges arrays.
 * @returns {Node[]} returns.nodes - The updated array of nodes.
 * @returns {Edge[]} returns.edges - The updated array of edges.
 */
export const getNodesForDelete = ({ nodeId, nodes, edges }: { nodeId: string; nodes: Node[]; edges: Edge[] }) => {
  const nodeToDelete = nodes.find((node) => node.id === nodeId);
  if (!nodeToDelete) {
    console.error('Could not find node to delete: PC-l93hd7a');
    return { nodes, edges };
  }

  const isAssociative = isAssociativeNode(nodeToDelete.type);
  if (isAssociative) {
    // Get all descendant nodes of the nodeToDelete.
    const descendantNodes = getDescendantNodes({ nodeId, nodes: nodes, edges: edges });
    const descendantNodeIds = descendantNodes.map((node) => node.id);

    // Filter out the nodeToDelete and all descendant nodes from the nodes array.
    const newNodes = nodes.filter((node) => !descendantNodeIds.includes(node.id) && node.id !== nodeId);
    // Filter out all edges that connect any descendant nodes of the nodeToDelete and also the edge that connects the
    // parent node to the nodeToDelete and the edge that connects the node to delete to it's child node (if it exists).
    const newEdges = edges.filter(
      (edge) => !descendantNodeIds.includes(edge.sourceId) && edge.targetId !== nodeId && edge.sourceId !== nodeId
    );

    return sortEdgesDepthFirst({ nodes: newNodes, edges: newEdges });
  } else {
    // The edge that connects the parent node to the nodeToDelete
    const parentEdge = edges.find((edge) => edge.targetId === nodeId);
    // The edge that connects the nodeToDelete to it's child node (if it exists).
    const childEdge = edges.find((edge) => edge.sourceId === nodeId);

    // Filter out the nodeToDelete from the nodes array.
    const newNodes = nodes.filter((node) => node.id !== nodeId);
    // Filter out the edge that point out of the nodeToDelete from the edges array.
    const newEdges = edges.filter((edge) => edge.sourceId !== nodeId);

    // If the parentEdge and childEdge exist, then we need to update the targetId of the parentEdge
    // to target the child node of the nodeToDelete.
    if (parentEdge && childEdge) {
      const index = newEdges.findIndex((edge) => edge.sourceId === parentEdge.sourceId);
      newEdges[index] = {
        ...newEdges[index],
        targetId: childEdge?.targetId ?? '',
      };
    }

    if (!childEdge) {
      // If the nodeToDelete is a leaf node, then we need to remove the edge that connects the parent node to the nodeToDelete.
      const index = newEdges.findIndex((edge) => edge.targetId === nodeId);
      newEdges.splice(index, 1);
    }

    return sortEdgesDepthFirst({ nodes: newNodes, edges: newEdges });
  }
};

/**
 * Generates a new node and edge for a call route.
 *
 * @param {Object} params - The parameters for creating the new node and edge.
 * @param {NodeTypes} params.nodeType - The type of the node to be created.
 * @param {string} params.parentNodeId - The ID of the parent node.
 * @param {string} params.name - The name of the node.
 * @param {boolean} [params.isPhoneTreeDescendent=false] - Indicates if the node is a descendant of a phone tree.
 *
 * @returns {Object} An object containing the new node and edge.
 * @returns {Object.node} The newly created node.
 * @returns {Object.edge} The newly created edge.
 */
export const getNewNodeAndEdge = ({
  nodeType,
  parentNodeId,
  name,
  isPhoneTreeDescendent = false,
}: {
  nodeType: NodeTypes;
  parentNodeId: string;
  name: string;
  isPhoneTreeDescendent?: boolean;
}) => {
  const newNode = {
    id: genUUIDV4(),
    type: nodeType,
    callObject: {
      primitiveId: '',
      primitiveName: name,
      instructionId: '',
      instructionSetId: '',
      // Adding callGroupExpansion property here because the schema expects one of the expansion object types
      // to be present in the callObject property. Remove this when the schema is updated.
      callGroupExpansion: {
        callerLabel: '',
      },
    },
    isPhoneTreeDescendent,
  };

  const newEdge = {
    id: `${parentNodeId}:${newNode.id}`,
    sourceId: parentNodeId,
    targetId: newNode.id,
    type: 'basic',
  };

  return {
    node: newNode,
    edge: newEdge,
  };
};

/**
 * Sorts the edges of a graph in a depth-first order with specific requirements for PhoneTree and OfficeHours nodes.
 * For PhoneTree nodes, their direct children are sorted alphabetically by primitiveName, with Fallback nodes last.
 * For OfficeHours nodes, their direct children are sorted by type in the following order: OpenPhoneHours first,
 * ClosedPhoneHours second, any BreakPhoneHours alphabetically.
 *
 * @param {Object} params - The parameters for the function.
 * @param {Node[]} params.nodes - The array of all nodes in the graph.
 * @param {Edge[]} params.edges - The array of all edges in the graph.
 * @returns {Object} - Object containing nodes and sorted edges.
 * @returns {Node[]} returns.nodes - The input nodes.
 * @returns {Edge[]} returns.edges - The edges sorted according to requirements.
 */
export const sortEdgesDepthFirst = ({
  nodes,
  edges,
}: {
  nodes: Node[];
  edges: Edge[];
}): { nodes: Node[]; edges: Edge[] } => {
  // Create a map to track visited nodes
  const visited = new Set<string>();

  // Array to store sorted edges
  const sortedEdges: Edge[] = [];

  // Find the Start node
  const startNode = nodes.find((node) => node.type === NodeType.Start);

  if (!startNode) {
    console.warn('No Start node found in the graph, returning original order');
    return { nodes, edges };
  }

  // Helper function to get node by ID
  const getNodeById = (id: string): Node | undefined => {
    return nodes.find((node) => node.id === id);
  };

  // Helper function for depth-first traversal
  const dfs = (nodeId: string) => {
    // Mark node as visited
    visited.add(nodeId);

    const currentNode = getNodeById(nodeId);
    if (!currentNode) return;

    // Find all outgoing edges from this node
    let outgoingEdges = edges.filter((edge) => edge.sourceId === nodeId);

    // Apply special sorting for PhoneTree nodes
    if (currentNode.type === NodeType.PhoneTree) {
      // Get target nodes to sort them
      const targetNodes = outgoingEdges
        .map((edge) => {
          const targetNode = getNodeById(edge.targetId);
          return { edge, targetNode };
        })
        .filter((item) => item.targetNode !== undefined) as { edge: Edge; targetNode: Node }[];

      // Sort target nodes: Fallback nodes last, others alphabetically by primitiveName
      targetNodes.sort((a, b) => {
        // Fallback nodes always last
        if (a.targetNode.type === NodeType.Fallback && b.targetNode.type !== NodeType.Fallback) return 1;
        if (a.targetNode.type !== NodeType.Fallback && b.targetNode.type === NodeType.Fallback) return -1;

        // Sort alphabetically by primitiveName
        const aName = a.targetNode.callObject?.primitiveName || '';
        const bName = b.targetNode.callObject?.primitiveName || '';
        return aName.localeCompare(bName);
      });

      // Update outgoingEdges to reflect the sorting
      outgoingEdges = targetNodes.map((item) => item.edge);
    }
    // Apply special sorting for OfficeHours nodes
    else if (currentNode.type === NodeType.OfficeHours) {
      // Get target nodes to sort them
      const targetNodes = outgoingEdges
        .map((edge) => {
          const targetNode = getNodeById(edge.targetId);
          return { edge, targetNode };
        })
        .filter((item) => item.targetNode !== undefined) as { edge: Edge; targetNode: Node }[];

      // Sort target nodes: OpenPhoneHours first, ClosedPhoneHours second, then BreakPhoneHours alphabetically
      targetNodes.sort((a, b) => {
        // OpenPhoneHours always first
        if (a.targetNode.type === NodeType.OpenPhoneHours && b.targetNode.type !== NodeType.OpenPhoneHours) return -1;
        if (a.targetNode.type !== NodeType.OpenPhoneHours && b.targetNode.type === NodeType.OpenPhoneHours) return 1;

        // ClosedPhoneHours second
        if (a.targetNode.type === NodeType.ClosedPhoneHours && b.targetNode.type !== NodeType.ClosedPhoneHours)
          return -1;
        if (a.targetNode.type !== NodeType.ClosedPhoneHours && b.targetNode.type === NodeType.ClosedPhoneHours)
          return 1;

        // If both are BreakPhoneHours, sort alphabetically
        if (a.targetNode.type === NodeType.BreakPhoneHours && b.targetNode.type === NodeType.BreakPhoneHours) {
          const aName = a.targetNode.callObject?.primitiveName || '';
          const bName = b.targetNode.callObject?.primitiveName || '';
          return aName.localeCompare(bName);
        }

        return 0;
      });

      // Update outgoingEdges to reflect the sorting
      outgoingEdges = targetNodes.map((item) => item.edge);
    }

    // Add these edges to our sorted result and continue traversal
    for (const edge of outgoingEdges) {
      sortedEdges.push(edge);

      // Continue depth-first if target hasn't been visited
      if (!visited.has(edge.targetId)) {
        dfs(edge.targetId);
      }
    }
  };

  // Start DFS from the Start node
  dfs(startNode.id);

  // Check if we've missed any edges (for disconnected components)
  const missedEdges = edges.filter(
    (edge) =>
      !sortedEdges.some((sortedEdge) => sortedEdge.sourceId === edge.sourceId && sortedEdge.targetId === edge.targetId)
  );

  // Append any missed edges to the end
  if (missedEdges.length > 0) {
    console.warn(`${missedEdges.length} edges were not reached in depth-first traversal starting from Start node`);
    sortedEdges.push(...missedEdges);
  }

  return { nodes, edges: sortedEdges };
};

/**
 * Compares two directed graphs to determine if they are structurally equal.
 *
 * Takes two sets of nodes and edges and determines if the graph structure that they represent are the same.
 * The graphs are considered the same if they have the same amount of nodes and edges and the edges' source and
 * targets are the same. Since the nodes and edges don't have reliable Ids, we can't compare them directly by the
 * node Id.
 * The nodes must have the same node.type and node.callObject.primitiveId unless the node
 * type is of type "officeHours" or "fallback" (those node types need to be compared differently since they don't
 * have primitiveIds).
 * For officeHours nodes, the nodes are considered the same if they have the same node.type and if the
 * node.callObject.phoneHoursExpansion.phoneHours array has the same elements compared by element.scheduleId.
 * For fallback nodes, the nodes are considered the same if they have the same node.type.
 *
 *  Traverses both graphs and compare the nodes and edges by the criteria above.
 *
 * Special handling is applied for specific node types:
 * - `NodeType.OfficeHours`: Compares `phoneHoursExpansion` objects.
 * - `NodeType.Fallback`: Considers nodes equal if their types match.
 * - Other node types: Compares the `primitiveId` of their `callObject`.
 *
 * The function performs a depth-first traversal starting from the `Start` node type
 * and recursively compares nodes and edges.
 *
 * @param graph1 - The first graph to compare, containing nodes and edges.
 * @param graph2 - The second graph to compare, containing nodes and edges.
 * @returns `true` if the graphs are structurally equal, otherwise `false`.
 */
export const areGraphsEqual = (graph1: { nodes: Node[]; edges: Edge[] }, graph2: { nodes: Node[]; edges: Edge[] }) => {
  const { nodes: nodes1, edges: edges1 } = graph1;
  const { nodes: nodes2, edges: edges2 } = graph2;

  // If the number of nodes and edges are different, then the graphs are not equal.
  if (nodes1.length !== nodes2.length || edges1.length !== edges2.length) {
    return false;
  }

  // Create a map to track visited nodes
  const visited = new Set<string>();

  // Helper function to get node by ID
  const getNodeById = (id: string, nodes: Node[]): Node | undefined => {
    return nodes.find((node) => node.id === id);
  };

  // Helper function for depth-first traversal
  const dfs = (nodeId1: string, nodeId2: string) => {
    // Mark node as visited
    visited.add(nodeId1);

    const node1 = getNodeById(nodeId1, nodes1);
    const node2 = getNodeById(nodeId2, nodes2);

    if (!node1 || !node2) return false;

    // Compare the nodes
    if (node1.type !== node2.type) {
      return false;
    }

    if (node1.type === NodeType.OfficeHours) {
      // Compare the phoneHoursExpansion objects
      // @ts-expect-error ignore this until we get better types
      const phoneHours1 = node1.callObject.phoneHoursExpansion?.phoneHours ?? [];
      // @ts-expect-error ignore this until we get better types
      const phoneHours2 = node2.callObject.phoneHoursExpansion?.phoneHours ?? [];

      if (phoneHours1.length !== phoneHours2.length) {
        return false;
      }

      for (let i = 0; i < phoneHours1.length; i++) {
        if (phoneHours1[i].scheduleId !== phoneHours2[i].scheduleId) {
          return false;
        }
      }
    } else if (node1.type === NodeType.Fallback) {
      // Fallback nodes are considered the same if they have the same type
      if (node1.type !== node2.type) {
        return false;
      }
    } else {
      // Compare the primitiveId
      if (node1.callObject.primitiveId !== node2.callObject.primitiveId) {
        return false;
      }
    }

    // Find all outgoing edges from this node
    const outgoingEdges1 = edges1.filter((edge) => edge.sourceId === nodeId1);
    const outgoingEdges2 = edges2.filter((edge) => edge.sourceId === nodeId2);

    // If the number of outgoing edges are different, then the graphs are not equal.
    if (outgoingEdges1.length !== outgoingEdges2.length) {
      return false;
    }

    // Continue traversal
    for (let i = 0; i < outgoingEdges1.length; i++) {
      const edge1 = outgoingEdges1[i];
      const edge2 = outgoingEdges2[i];

      const targetNode1 = getNodeById(edge1.targetId, nodes1);
      const targetNode2 = getNodeById(edge2.targetId, nodes2);

      // Compare the edges
      if (targetNode1?.callObject.primitiveId !== targetNode2?.callObject.primitiveId) {
        return false;
      }

      // Continue depth-first if target hasn't been visited
      if (!visited.has(edge1.targetId) && !dfs(edge1.targetId, edge2.targetId)) {
        return false;
      }
    }

    return true;
  };

  // Start DFS from the Start node
  return dfs(
    nodes1.find((node) => node.type === NodeType.Start)?.id ?? '',
    nodes2.find((node) => node.type === NodeType.Start)?.id ?? ''
  );
};

/**
 * Recursively iterates over the properties of an object and executes a callback function for each property.
 * NOTE: if the property is of type object, the callback function is executed for each property of the object
 * but the callback function is not executed for the object itself.
 *
 * @param obj - The object whose properties will be iterated.
 * @param callback - A function to execute for each property. It receives the following parameters:
 *   - `key`: The key of the current property.
 *   - `value`: The value of the current property.
 *   - `path`: The dot-separated path to the current property.
 * @param path - The current path being traversed (used internally for recursion). Defaults to an empty string.
 */
function iterateProperties(
  obj: Record<string, any>,
  callback: (key: string, value: any, path: string) => void,
  path = ''
) {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newPath = path ? path + '.' + key : key;

      // If the property is an object (and not null), recursively iterate its properties
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        iterateProperties(obj[key], callback, newPath);

        // Skip executing the callback for the current property if it is an object
        return;
      }

      // Execute the callback for the current property
      callback(key, obj[key], newPath);
    }
  }
}

/**
 * Reconciles the current state of graph nodes in their editing state with the latest and previous states
 * from the backend (BE). This function ensures that only the properties that have changed on the backend
 * are updated in the current editing state, while preserving the rest of the data.
 *
 * @param {Object} params - The parameters for the reconciliation process.
 * @param {Node[]} params.tempEditingNodes - The current state of the nodes in their editing state.
 * @param {Node[]} params.latestNodes - The latest state of the nodes from the backend.
 * @param {Node[]} params.prevNodes - The previous state of the nodes from the backend.
 * @returns {Node[]} - A new array of nodes with reconciled data.
 *
 * @remarks
 * - If a node's type has changed between the current state and the latest state, it is skipped.
 * - Only properties that have changed on the backend (i.e., differ from the previous state) are updated.
 * - The function ensures immutability by cloning the current node before applying updates.
 *
 * @example
 * const reconciledNodes = reconcileGraphNodes({
 *   tempEditingNodes: editingNodes,
 *   latestNodes: backendLatestNodes,
 *   prevNodes: backendPrevNodes,
 * });
 */
export const reconcileGraphNodes = ({
  tempEditingNodes,
  latestNodes,
  prevNodes,
}: {
  tempEditingNodes: Node[];
  latestNodes: Node[];
  prevNodes: Node[];
}): Node[] => {
  const newNodes = [...tempEditingNodes];

  // Go through every node in the editing state of the callroute and update it with the latest state of the node from
  // the BE if it exists.
  for (let i = 0; i < newNodes.length; i++) {
    const currentNode = newNodes[i]; // The current node in the callroute (this is the current state of the node in it's editing state)
    const latestNode = latestNodes.find((node) => node.id === currentNode.id); // the latest state of the node from the BE
    const prevNode = prevNodes.find((node) => node.id === currentNode.id); // the previous state of the node from the BE

    // First Check if the currentNode is the same type as the latest Node, if not, skip this since that means the node
    // has changed type so the data should be different.
    if (latestNode && currentNode.type === latestNode.type) {
      // Clone the currentNode object so we don't mutate the original object (or it's nested objects)
      const updatedNode = cloneDeep(currentNode);
      // Go through every property of the currentNode object and update it with the latestNode object properties if it exists,
      // but only if the latestNode object property has changed from the previousNode object property since this means that
      // the value has changed on the BE from what we previously had.
      iterateProperties(currentNode, (_, currentValue, path) => {
        const latestValue = get(latestNode, path);
        const prevValue = get(prevNode, path);
        if (currentValue !== latestValue && latestValue !== prevValue) {
          set(updatedNode, path, latestValue);
        }
      });
      newNodes[i] = updatedNode;
    }
  }

  return newNodes;
};
