import { ScheduleRequestCountResponse } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { ReviewedStatus } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/booking_submission.pb';
import { noop } from 'lodash-es';
import { useQuery } from 'react-query';
import { ScheduleApi, ScheduleQueries } from '@frontend/api-schedule';
import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { useAppScopeStore } from '@frontend/scope';

type GetScheduleRequestAndAlertsCountType = {
  scheduleAlertsCount: number;
  isScheduleAlertsLoading: boolean;
  isAppointmentRequestCountLoading: boolean;
  scheduleRequestCount: ScheduleRequestCountResponse | undefined;
  refetchAlertCount: () => void;
  refetchAppointmentRequestCount: () => void;
};

export const useGetScheduleRequestAndAlertsCount = (): GetScheduleRequestAndAlertsCountType => {
  const { selectedLocationIds } = useAppScopeStore();
  const { isScheduleV3FlagEnabled } = SchedulerV3.Helpers.useGetSchedulerV3FeatureFlagDetails();

  const {
    data: scheduleAlertsCount,
    isLoading: isScheduleAlertsLoading,
    refetch: refetchAlertCount,
  } = useQuery({
    queryKey: ['scheduleAlertsCount', selectedLocationIds],
    queryFn: async () => await ScheduleApi.getScheduleAlertsCount(selectedLocationIds),
  });

  const scheduleRequestCountQueryV2 = ScheduleQueries.useGetScheduleRequestCount(
    {
      locationIds: selectedLocationIds,
      statuses: 'Pending',
    },
    !isScheduleV3FlagEnabled
  );

  const scheduleRequestCountQueryV3 = SchedulerV3.Queries.useListBookingSubmissionsQueries(
    selectedLocationIds.map((locationId) => ({
      request: {
        locationsIds: [locationId],
        reviewedStatuses: [ReviewedStatus.PENDING],
        limit: 1,
      },
      options: {
        enabled: isScheduleV3FlagEnabled && !!locationId,
        select: (data) => ({ locationId, total: data.totalCount ? +data.totalCount : 0 }),
      },
    }))
  );

  const isAppointmentRequestCountLoading = isScheduleV3FlagEnabled
    ? scheduleRequestCountQueryV3.some((query) => query.isLoading)
    : scheduleRequestCountQueryV2.isLoading;

  // For v3, refetch is handled by the query itself
  const refetchAppointmentRequestCount = isScheduleV3FlagEnabled ? noop : scheduleRequestCountQueryV2.refetch;

  const scheduleRequestCount: ScheduleRequestCountResponse | undefined = isScheduleV3FlagEnabled
    ? {
        total: scheduleRequestCountQueryV3.reduce((acc, query) => acc + (query.data?.total ?? 0), 0),
        locationScheduleRequestCounts: scheduleRequestCountQueryV3.map((query) => ({
          locationId: query.data?.locationId ?? '',
          total: query.data?.total ?? 0,
        })),
      }
    : scheduleRequestCountQueryV2.data;

  return {
    scheduleAlertsCount: scheduleAlertsCount || 0,
    isScheduleAlertsLoading,
    refetchAlertCount,
    scheduleRequestCount,
    isAppointmentRequestCountLoading,
    refetchAppointmentRequestCount,
  };
};
