import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { Provider } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { CommonHTMLAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';
import { Chip, Text } from '@frontend/design-system';
import { DropdownListSelector } from '../../../components/DropdownListSelector';
import { ProviderAvatar } from '../../../components/ServiceProviderAvatar';
import { isUUID } from '../utils';
import { UserIconContainer } from './UserIconContainer';

type ProviderDropdownListSelectorProps = {
  providersList: Provider[];
  selectedLocationIds: string[];
  providerDropdownListSelectorProps: {
    onChange: (value: string) => void;
    value: string;
  };
  hasDottedBorder?: boolean;
  shouldHaveBorder?: boolean;
  listWidth?: number;
  filterProviderByProviderUUID?: boolean;
  locationIdsToFilterProviders?: string[];
  customProviderList?: Provider[];
  hideLocationTag?: boolean;
} & Omit<CommonHTMLAttributes, 'onChange'>;

export const ProviderDropdownListSelector = React.memo(
  ({
    providersList,
    selectedLocationIds,
    providerDropdownListSelectorProps,
    listWidth = 200,
    hasDottedBorder = false,
    shouldHaveBorder = false,
    filterProviderByProviderUUID = false,
    locationIdsToFilterProviders = [],
    customProviderList,
    hideLocationTag = false,
    ...rest
  }: ProviderDropdownListSelectorProps) => {
    const { t } = useTranslation('schedule');
    const { getScopeName } = useAppScopeStore();

    const filteredProvidersList = useMemo(() => {
      // filter providers based on whether they have id as uuid
      const providerListData =
        filterProviderByProviderUUID && providersList
          ? providersList?.filter((provider) => isUUID(provider.id ?? ''))
          : providersList;

      if (!!customProviderList?.length) {
        return providerListData.filter(({ id: providerId }) => {
          return customProviderList.every(
            ({ id: customProviderId }) => customProviderId && providerId && !customProviderId.includes(providerId)
          );
        });
      }

      return providerListData.filter((provider) => {
        const locationIdsToFilterProvidersData = locationIdsToFilterProviders.length
          ? locationIdsToFilterProviders
          : selectedLocationIds;

        const isProviderPresent = locationIdsToFilterProvidersData.includes(provider.locationId ?? '');

        if (filterProviderByProviderUUID) {
          return isProviderPresent && provider.id && isUUID(provider.id);
        }
        return isProviderPresent;
      });
    }, [
      selectedLocationIds,
      providersList,
      filterProviderByProviderUUID,
      locationIdsToFilterProviders,
      customProviderList,
    ]);

    return (
      <DropdownListSelector
        placeHolder={t('Select Provider')}
        name='search-provider-selector'
        shouldHaveBorder={shouldHaveBorder}
        hasDottedBorder={hasDottedBorder}
        icon={<UserIconContainer />}
        listWidth={listWidth}
        shouldShowOnlyDisplayValueOnSelect={true}
        {...rest}
        {...providerDropdownListSelectorProps}
      >
        {!!customProviderList && customProviderList?.length > 0 && (
          <Text weight='bold' css={textStyles}>
            {t('Available Providers')}
          </Text>
        )}
        {customProviderList?.map((provider, index) => {
          const providerFullName = `${provider.firstName} ${provider.lastName}`;
          return (
            <DropdownListSelector.Option
              icon={<ProviderAvatar provider={provider} size={3} key={provider.id} />}
              key={(provider.id ?? '') + index}
              value={provider.id ?? ''}
              searchValue={providerFullName}
              displayValue={providerFullName}
            >
              <div css={{ display: 'flex', alignItems: 'center' }}>
                <Text as='span' size='medium'>
                  {providerFullName}
                </Text>
                {hideLocationTag ? null : (
                  <Chip css={locationChipContainerStyles}>
                    <span css={{ display: 'flex', alignItems: 'center' }}>
                      <Icon name='location' size={16} color='light' />
                      <Text as='span' size='small' color='light' css={{ marginLeft: theme.spacing(0.25) }}>
                        {getScopeName(provider.locationId ?? '')}
                      </Text>
                    </span>
                  </Chip>
                )}
              </div>
            </DropdownListSelector.Option>
          );
        })}
        {customProviderList && customProviderList?.length > 0 && (
          <Text weight='bold' css={textStyles}>
            {t('All Providers')}
          </Text>
        )}
        {filteredProvidersList.map((provider, index) => {
          const providerFullName = `${provider.firstName} ${provider.lastName}`;
          return (
            <DropdownListSelector.Option
              icon={<ProviderAvatar provider={provider} size={3} key={provider.id} />}
              key={(provider.id ?? '') + index}
              value={provider.id ?? ''}
              searchValue={providerFullName}
              displayValue={providerFullName}
            >
              <div css={{ display: 'flex', alignItems: 'center' }}>
                <Text as='span' size='medium'>
                  {providerFullName}
                </Text>
                {hideLocationTag ? null : (
                  <Chip css={locationChipContainerStyles}>
                    <span css={{ display: 'flex', alignItems: 'center' }}>
                      <Icon name='location' size={16} color='light' css={{ maxWidth: 'max-content' }} />
                      <Text as='span' size='small' color='light' css={{ marginLeft: theme.spacing(0.25) }}>
                        {getScopeName(provider.locationId ?? '')}
                      </Text>
                    </span>
                  </Chip>
                )}
              </div>
            </DropdownListSelector.Option>
          );
        })}
      </DropdownListSelector>
    );
  }
);

ProviderDropdownListSelector.displayName = 'ProviderDropdownListSelector';

const locationChipContainerStyles = css({
  marginLeft: 'auto',
  background: theme.colors.neutral5,
});

const textStyles = css({
  margin: `${theme.spacing(2, 1, 1, 1)}`,
});
