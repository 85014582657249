import { ScheduleApi, ScheduleTypes } from '@frontend/api-schedule';
import { ServiceProvidersConstants } from '@frontend/api-service-providers';
import { useQuery } from '@frontend/react-query-helpers';
import { queryKeys } from '../../query-keys';

const { getProvidersListForMultipleLocationIds } = ScheduleApi;
const { ONE_MINUTE_IN_MILLISECONDS } = ServiceProvidersConstants;

type UseGetProvidersListByLocationIdsParamsType = ScheduleTypes.GetProvidersListForMultipleLocationIdsTypes['input'];

export const useGetProvidersListForMultipleLocationIds = (
  request: UseGetProvidersListByLocationIdsParamsType,
  enabled = true
) => {
  const queryKeysParams = request.locationIds?.length ? request.locationIds : [request.locationId ?? ''];
  return useQuery({
    queryKey: queryKeys.providersList(queryKeysParams),
    queryFn: async () => {
      try {
        const res = await getProvidersListForMultipleLocationIds(request);
        const { providers } = res ?? {};
        if (!providers) return [];
        return providers.sort((a, b) => {
          if (
            a?.publicDisplayName?.toLowerCase() &&
            b?.publicDisplayName?.toLowerCase() &&
            a.publicDisplayName?.toLowerCase() < b.publicDisplayName?.toLowerCase()
          ) {
            return -1;
          }
          return 1;
        });
      } catch (error) {
        if ((error as { message?: string })?.message?.includes('too many')) {
          console.error('Error fetching providers list', error);
          return [];
        } else {
          throw error;
        }
      }
    },
    retry: 1,
    cacheTime: 5 * ONE_MINUTE_IN_MILLISECONDS, // 5 mins
    staleTime: 5 * ONE_MINUTE_IN_MILLISECONDS, // 5 mins
    enabled: (!!request.locationIds?.length || !!request.locationId) && enabled,
  });
};
