import { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Tabs, NotificationBadge } from '@frontend/design-system';
import { LocationAccordionList } from '../../../../components/location-accordion-list';
import { useSchedulingLocationInfo } from '../../../../hooks';
import { schedulePulseSelectedTabStorage } from '../../../../utils';
import { useScheduleActionsContext } from './ScheduleActionsContext';
import { ScheduleAlerts } from './ScheduleAlerts';
import { ScheduleMassMessages } from './ScheduleMassMessages/ScheduleMassMessages';
import { ScheduleRequestList } from './ScheduleRequests/ScheduleRequestList';
import { ScheduleRequestTabHeader } from './ScheduleRequests/ScheduleRequestTabHeader';

enum ScheduleActionsTab {
  AppointmentRequests = 'schedule-appointment-requests-tab',
  Alerts = 'schedule-alerts-tab',
  AppointmentList = 'schedule-appointments-list-tab',
}

export const ScheduleActionsTabContent = () => {
  const { t } = useTranslation('schedule-pulse');
  const { scheduleAlertsTotalCount, scheduleRequestsCountByLocation, isAppointmentRequestCountLoading } =
    useScheduleActionsContext();

  const { selectedLocationIds, getScopeName } = useAppScopeStore();
  const selectedTab = schedulePulseSelectedTabStorage.get();
  const { getCFLocationMap } = useSchedulingLocationInfo();
  const onlineSchedulingCFLocationMap = getCFLocationMap('onlinescheduling');

  useEffect(() => {
    return () => {
      // reset selected tab on unmount
      if (selectedTab) {
        schedulePulseSelectedTabStorage.reset();
      }
    };
  }, []);

  const isRequestTabEnabled = Object.values(onlineSchedulingCFLocationMap).some((value) => !!value);
  const scheduleRequestLocationInfoList = useMemo(() => {
    if (!isRequestTabEnabled) return [];

    // filter locations that have scheduling flag enabled
    return selectedLocationIds
      .filter((locationId) => !!onlineSchedulingCFLocationMap?.[locationId])
      .map((locationId) => ({
        locationId,
        name: getScopeName(locationId),
        count: scheduleRequestsCountByLocation.find((item) => item.locationId === locationId)?.total ?? 0,
      }));
  }, [selectedLocationIds, onlineSchedulingCFLocationMap, scheduleRequestsCountByLocation, isRequestTabEnabled]);

  const scheduleRequestTotalCount = scheduleRequestLocationInfoList.reduce((acc, { count }) => acc + count, 0);

  const initialTab =
    isRequestTabEnabled && selectedTab === 'appointment-requests'
      ? ScheduleActionsTab.AppointmentRequests
      : ScheduleActionsTab.AppointmentList;

  return (
    <Tabs initialTab={initialTab}>
      <Tabs.Bar css={{ padding: theme.spacing(0, 3) }}>
        <Tabs.Tab trackingId={`${ScheduleActionsTab.AppointmentList}-panel`} id={ScheduleActionsTab.AppointmentList}>
          {t('Appointment List')}
        </Tabs.Tab>
        {isRequestTabEnabled && (
          <Tabs.Tab
            trackingId={`${ScheduleActionsTab.AppointmentRequests}-panel`}
            id={ScheduleActionsTab.AppointmentRequests}
          >
            <ScheduleRequestTabHeader count={scheduleRequestTotalCount} isLoading={isAppointmentRequestCountLoading} />
          </Tabs.Tab>
        )}
        <Tabs.Tab trackingId={`${ScheduleActionsTab.Alerts}-panel`} id={ScheduleActionsTab.Alerts}>
          <span css={[tabStyles, { gap: theme.spacing(1) }]}>
            {t('Alerts')}
            {!!scheduleAlertsTotalCount && <NotificationBadge>{scheduleAlertsTotalCount}</NotificationBadge>}
          </span>
        </Tabs.Tab>
      </Tabs.Bar>
      <Tabs.Panel css={tabListPanelContainerStyles} controller={ScheduleActionsTab.AppointmentList}>
        <ScheduleMassMessages />
      </Tabs.Panel>
      {isRequestTabEnabled && (
        <Tabs.Panel css={tabListPanelContainerStyles} controller={ScheduleActionsTab.AppointmentRequests}>
          <LocationAccordionList
            locationInfoList={scheduleRequestLocationInfoList}
            ContentComponent={ScheduleRequestList}
            minBodyHeight={381}
          />
        </Tabs.Panel>
      )}
      <Tabs.Panel css={tabListPanelContainerStyles} controller={ScheduleActionsTab.Alerts}>
        <ScheduleAlerts />
      </Tabs.Panel>
    </Tabs>
  );
};

const tabStyles = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const tabListPanelContainerStyles = css({
  padding: theme.spacing(3),
  backgroundColor: theme.colors.neutral5,
  height: '100%',
  overflowY: 'auto',
});
