import { useEffect, useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Text, IconButton } from '@frontend/design-system';
import { DropdownListSelector, useDropdownListSelector } from '../../../../../components/DropdownListSelector';

const MAXIMUM_LOCATIONS = 4;

type LocationSelectorProps = {
  locationIds: string[];
  updateLocationIds: (locationIds: string[]) => void;
};

export const LocationSelector = ({ locationIds, updateLocationIds }: LocationSelectorProps) => {
  const { getScopeName, selectedLocationIds } = useAppScopeStore();
  const { t } = useTranslation('scheduleCalendarFilters');

  const dropdownListSelectorProps = useDropdownListSelector({
    value: '',
  });

  const handleClearLocation = (locationId: string) => {
    const updatedLocationIds = locationIds.filter((id) => id !== locationId);
    updateLocationIds(updatedLocationIds);
    dropdownListSelectorProps.onChange('');
  };

  const nonSelectedLocationIds = useMemo(
    () => selectedLocationIds.filter((locationId) => !locationIds.includes(locationId)),
    [locationIds]
  );

  useEffect(() => {
    if (dropdownListSelectorProps.value) {
      const updatedLocationIds = [...locationIds, dropdownListSelectorProps.value];
      updateLocationIds(updatedLocationIds);
      dropdownListSelectorProps.onChange('');
    }
  }, [dropdownListSelectorProps.value]);

  return (
    <section css={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: theme.spacing(1.5) }}>
      {locationIds.map((locationId, index) => {
        if (index < MAXIMUM_LOCATIONS) {
          return (
            <div
              key={locationId}
              css={{
                background: theme.colors.primary5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                borderRadius: theme.borderRadius.small,
                padding: theme.spacing(0.5, 1),
                width: 'fit-content',
              }}
            >
              <Icon name='location' size={16} />
              <Text
                size='medium'
                css={{
                  marginLeft: theme.spacing(1),
                }}
              >
                {getScopeName(locationId)}
              </Text>
              <IconButton
                onClick={() => handleClearLocation(locationId)}
                label={t('Cancel location')}
                css={{ paddingRight: 0, height: 0, marginLeft: 'auto', minWidth: 10, maxWidth: 24 }}
              >
                <Icon name='x' size={10} />
              </IconButton>
            </div>
          );
        }
        return null;
      })}
      {locationIds.length < MAXIMUM_LOCATIONS && !!nonSelectedLocationIds.length && (
        <DropdownListSelector
          placeHolder={t('Add location')}
          name='search-providers-selector'
          hasDottedBorder={true}
          height={32}
          css={{ width: 'fit-content' }}
          {...dropdownListSelectorProps}
          icon={
            <div
              css={{
                width: 16,
                height: 16,
                borderRadius: theme.borderRadius.full,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: theme.colors.primary10,
              }}
            >
              <Icon name='plus' color='primary' size={12} />
            </div>
          }
        >
          {nonSelectedLocationIds.map((locationId) => {
            return (
              <DropdownListSelector.Option value={locationId} searchValue={getScopeName(locationId)} key={locationId}>
                {getScopeName(locationId)}
              </DropdownListSelector.Option>
            );
          })}
        </DropdownListSelector>
      )}
    </section>
  );
};
