import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { AppointmentTypesTypes } from '@frontend/api-appointment-types';
import { ScheduleAvailabilityTypes } from '@frontend/api-schedule-availability';
import {
  Schedule,
  ScheduleWithRouting,
  TimeString,
  StampedOpenings,
  ScheduleAsset,
  OpeningBlock,
  ExplodeOpeningsParams,
  ExplodeOpeningsReturn,
  ExplodeNewOpeningsReturn,
} from './types';

dayjs.extend(timezone);

interface IsWithinRangeProps {
  currentTime: TimeString;
  startTime: TimeString;
  endTime: TimeString;
}

export const scheduleHasValues = (schedule: ScheduleAvailabilityTypes.Schedule) => {
  const values = schedule ? Object.values(schedule) : [];
  const hasValues = values?.filter((value) => {
    return value.length > 0;
  });
  return hasValues.length;
};

export const isWithinRange = ({ currentTime, startTime, endTime }: IsWithinRangeProps) => {
  /**
   * Parsing Date strings are discouraged due to browser inconsistencies,
   * but our use case is simple and should not be affected in any meaningful way.
   *
   * This dates below do not matter - they just have to be the same.
   */
  const currentDate = new Date(`1/1/2022 ${currentTime}`);
  const startDate = new Date(`1/1/2022 ${startTime}`);
  const endDate = new Date(`1/1/2022 ${endTime}`);

  return startDate <= currentDate && currentDate <= endDate;
};

export const isTimeZoneValid = (timezone: string): boolean => {
  try {
    // Attempt to create a DateTimeFormat object with the provided time zone
    new Intl.DateTimeFormat('en-US', { timeZone: timezone });
    return true;
  } catch (error) {
    console.error(`timezone: ${timezone}`, error);
    return false;
  }
};

export const scheduleIsCurrent = (schedule: Schedule | ScheduleWithRouting, timezone: string) => {
  const now = new Date();
  let hours = now.getHours();
  let minutes = now.getMinutes();
  let seconds = now.getSeconds();
  let dayOfWeek = now.getDay();

  if (!!timezone && isTimeZoneValid(timezone)) {
    const timezoneData = dayjs.tz(now, timezone);
    hours = timezoneData.hour();
    minutes = timezoneData.minute();
    seconds = timezoneData.second();
    dayOfWeek = timezoneData.day();
  }
  const rules = schedule.rules;
  return !!rules
    .filter((rule) => rule.dayOfWeek === dayOfWeek)
    .find((rule) =>
      isWithinRange({
        currentTime: `${hours}:${minutes}:${seconds}`,
        startTime: rule.startTime,
        endTime: rule.endTime,
      })
    );
};

export const elapsedSeconds = (time: string) => {
  const timeArray = time.split(':');
  const hour = parseInt(timeArray[0]);
  const minute = parseInt(timeArray[1]);
  const seconds = hour * 60 * 60 + minute * 60;
  return seconds;
};

export const elapsedHours = (time: string) => Math.floor(elapsedSeconds(time) / 60 / 60);
export const elapsedMinutes = (time: string) => Math.floor(elapsedSeconds(time) / 60);

export const formatOpenings = (openings: AppointmentTypesTypes.Opening[]): StampedOpenings => {
  return openings.reduce((prev: StampedOpenings, value: AppointmentTypesTypes.Opening) => {
    const formattedDate = dayjs(value.start).format('MM/DD/YYYY');
    return {
      ...prev,
      [formattedDate]: [...(prev[formattedDate] || []), value],
    };
  }, {});
};

type StatusListProps = {
  openings: StampedOpenings;
  start: string;
  end: string;
  provider?: string;
  assetFilter?: {
    [asset: string]: string[];
  };
};

type ISOTime = string;

export interface DaysStatusList {
  open: ISOTime[];
  closed: ISOTime[];
}

export const getStatusList = ({ openings, start, end }: StatusListProps): DaysStatusList => {
  const startDate = dayjs(start);
  const current = startDate.date();
  const month = startDate.month();
  const year = startDate.year();
  const daysDifference = dayjs(end).diff(start, 'days');

  let daysList: DaysStatusList = { open: [], closed: [] };

  for (let idx = 0; idx <= daysDifference; idx++) {
    const day = idx + current;
    const checkDate = dayjs(new Date(year, month, day)).format('MM/DD/YYYY');
    const key = checkDate in openings ? 'open' : 'closed';

    daysList = {
      ...daysList,
      [key]: [...daysList[key], checkDate],
    };
  }

  return daysList;
};

// nextMultiple finds the next multiple of a `multiplier` starting at `start`
const nextMultiple = (multiplier: number, start: number) => Math.ceil(start / multiplier) * multiplier;

const getAssetNames = (assets: ScheduleAsset[]) => assets.map((asset) => asset.name);
export const convertToMinutes = (value: string) => Math.round(parseInt(value) / 60);

export const explodeAvailableOpenings = ({
  openings,
  durationMinutes,
  cadence,
}: ExplodeOpeningsParams): AppointmentTypesTypes.Opening[] => {
  if (durationMinutes < 5) {
    durationMinutes = 15;
    console.warn('duration was set to less than five, that is not good');
  }

  const openingsMap = openings.map((opening) => {
    const explodedOpenings: AppointmentTypesTypes.Opening[] = [];
    const openingDuration = convertToMinutes(opening?.duration);
    const workstations = getAssetNames(opening?.assetMap?.workstations ?? []);
    const providers = getAssetNames(opening?.assetMap?.providers ?? []);

    if (cadence !== null) {
      let startMinutes = 0;
      while (startMinutes + durationMinutes <= openingDuration) {
        let startTime = dayjs(opening?.start).add(startMinutes, 'minute');
        const startTimeMinutes = dayjs(startTime).minute();
        // If cadence is zero we want each opening to fall on the hour so we adjust to 60
        const cadenceMultiplier = !cadence ? 60 : cadence;
        // find the next interval of the specified cadence, then add the difference to startMinutes
        const minutesTilCadence = nextMultiple(cadenceMultiplier, startTimeMinutes) - startTimeMinutes;
        if (minutesTilCadence + startMinutes + durationMinutes <= openingDuration) {
          startMinutes += minutesTilCadence;
          startTime = dayjs(opening?.start).add(startMinutes, 'minute');
          explodedOpenings.push({
            start: startTime.toISOString(),
            duration: String(durationMinutes),
            assets: [...providers, ...workstations],
            assetMap: opening?.assetMap,
          });
        }
        startMinutes += durationMinutes;
      }
    } else {
      for (let i = 0; i < openingDuration; i += durationMinutes) {
        if (i + durationMinutes <= openingDuration) {
          const start = dayjs(opening?.start).add(i, 'minute').toISOString();
          explodedOpenings.push({
            start,
            duration: String(durationMinutes),
            assets: [...providers, ...workstations],
            assetMap: opening?.assetMap,
          });
        }
      }
    }
    return explodedOpenings;
  });
  // transform the array of arrays into an array of objects
  return openingsMap.reduce((acc, val) => [...acc, ...val], []);
};

const distinctStartTimes = (openings: AppointmentTypesTypes.Opening[]) => {
  const result: AppointmentTypesTypes.Opening[] = [];
  const map = new Map();
  for (const opening of openings) {
    if (!map.has(opening.start)) {
      map.set(opening.start, true);
      result.push(opening);
    }
  }
  return result;
};
const distinctNewStartTimes = (openings: OpeningBlock[]) => {
  const result: OpeningBlock[] = [];
  const map = new Map();
  for (const opening of openings) {
    if (!map.has(opening.startTime)) {
      map.set(opening.startTime, true);
      result.push(opening);
    }
  }
  return result;
};

export const sortTimes = (openings: AppointmentTypesTypes.Opening[]): ExplodeOpeningsReturn => {
  if (!openings) {
    return { AM: [], PM: [] };
  }
  const distinctTimes = distinctStartTimes(openings);
  const sortedTimes = distinctTimes.sort((openA: AppointmentTypesTypes.Opening, openB: AppointmentTypesTypes.Opening) =>
    openA?.start.localeCompare(openB?.start)
  );
  const AM = sortedTimes.filter((opening) => {
    return dayjs(opening?.start).format('a') === 'am';
  });
  const PM = sortedTimes.filter((opening) => dayjs(opening?.start).format('a') === 'pm');
  return { AM, PM };
};
export const sortNewTimes = (openings: OpeningBlock[]): ExplodeNewOpeningsReturn => {
  if (!openings) {
    return { AM: [], PM: [] };
  }
  const distinctTimes = distinctNewStartTimes(openings);
  const sortedTimes = distinctTimes.sort((openA: OpeningBlock, openB: OpeningBlock) =>
    openA?.startTime.localeCompare(openB?.startTime)
  );
  const AM = sortedTimes.filter((opening) => {
    return dayjs(opening?.startTime).format('a') === 'am';
  });
  const PM = sortedTimes.filter((opening) => dayjs(opening?.startTime).format('a') === 'pm');
  return { AM, PM };
};
