import React from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, CheckboxField, TimeRangeField } from '@frontend/design-system';
import { DAYS_OF_WEEK } from './phone-hours-schedule.form';
import SunIcon from './sun-icon';

interface PhoneHoursGridProps {
  scheduleGetFieldProps: any;
  scheduleValues: Record<string, any>;
}

export const PhoneHoursGrid: React.FC<PhoneHoursGridProps> = ({ scheduleGetFieldProps, scheduleValues }) => {
  const { t } = useTranslation('phone');
  const labels = [t('Day'), t('Start Time'), t('End Time'), t('Break')];

  return (
    <div style={{ display: 'grid', gap: theme.spacing(2) }}>
      {/* Header Row */}
      <div
        style={{
          padding: theme.spacing(2),
          display: 'grid',
          gridTemplateColumns: `${theme.spacing(2.5)} ${theme.spacing(27)} ${theme.spacing(20)} ${theme.spacing(
            20
          )} ${theme.spacing(8.5)}`,
          alignItems: 'center',
          borderBottom: `1px solid ${theme.colors.neutral20}`,
        }}
      >
        <div></div>
        {labels.map((label) => (
          <Text key={label} size='medium' color='subdued'>
            {label}
          </Text>
        ))}
      </div>

      {/* Days of the Week Rows */}
      {DAYS_OF_WEEK.map((day) => (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: `${theme.spacing(4.5)} ${theme.spacing(27)} ${theme.spacing(40.5)} ${theme.spacing(
              8.5
            )}`,
            alignItems: 'center',
          }}
          key={day}
        >
          {/* Checkbox */}
          <div>
            <CheckboxField {...scheduleGetFieldProps(`selected-${day}`)} label='' hideErrorText />
          </div>

          {/* Icon and Label */}
          <div
            style={{
              display: 'flex',
              gap: theme.spacing(1),
              alignItems: 'center',
            }}
          >
            <div
              style={{
                backgroundColor: !!scheduleValues[`selected-${day}`] ? theme.colors.warning5 : theme.colors.neutral10,
                width: theme.spacing(4),
                height: theme.spacing(3),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '4px',
              }}
            >
              {!!scheduleValues[`selected-${day}`] ? <SunIcon /> : <Icon name='moon-small' />}
            </div>
            <Text>{day}</Text>
          </div>

          {/* TimeRangeField */}
          <TimeRangeField
            {...scheduleGetFieldProps(`range-${day}`)}
            label={day}
            interval={15}
            disabled={!scheduleValues[`selected-${day}`]}
            hideErrorText
            joiningText='to'
          />

          {/* Add Break Icon */}
          <Icon name='add-small' />
        </div>
      ))}
    </div>
  );
};
