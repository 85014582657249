import { ManualTemplatesTypes } from '@frontend/api-manual-templates';
import { http } from '@frontend/fetch';
import { SchemaIO, SchemaScheduleService } from '@frontend/schema';
import { QuickFillData, SelectedTime } from './types';

const manualTemplatesEndpoint = 'portal/v1/manualtemplates';
const quickFillEndpoint = '/schedule/api/v2/quick-fill';

type SchemaScheduleQuickfill = SchemaIO<(typeof SchemaScheduleService)['QuickfillList']>;

const LOCATION_ID_HEADER = 'Location-Id';

const getLocationIdHeader = (locationId: string) => ({
  headers: locationId ? { [LOCATION_ID_HEADER]: locationId } : undefined,
});

export const getQuickFillRecipients = (
  request: SchemaScheduleQuickfill['input'],
  locationId?: string
): Promise<SchemaScheduleQuickfill['output']> => {
  return SchemaScheduleService.QuickfillList(request, getLocationIdHeader(locationId ?? ''));
};

export const getDefaultTemplate = async (locationId = '') => {
  const data = await http.get<ManualTemplatesTypes.ManualTemplateResponse>(
    `${manualTemplatesEndpoint}`,
    getLocationIdHeader(locationId)
  );
  return data.data.find((template) => template.Type === 'schedule-quick-fill');
};

export const updateTemplate = async (message: string) => {
  await http.put<string>(`${manualTemplatesEndpoint}/schedule-quick-fill`, {
    Template: message,
  });
};

export const updateQuickFill = async (date: SelectedTime, recipientIdList: string[], locationId: string) => {
  await http.post<QuickFillData>(`${quickFillEndpoint}`, {
    candidates: recipientIdList,
    startTime: date?.startTime,
    endTime: date?.endTime,
    status: 'not_viewed',
    locationId,
  });
};

type SchemaSendQuickFillMessage = SchemaIO<(typeof SchemaScheduleService)['SendQuickFillMessage']>;

export const sendQuickfillMessage = (
  request: SchemaSendQuickFillMessage['input']
): Promise<SchemaSendQuickFillMessage['output']> => {
  return SchemaScheduleService.SendQuickFillMessage(request, getLocationIdHeader(request.locationId ?? ''));
};

type SchemaGetQuickFillMessageHistory = SchemaIO<(typeof SchemaScheduleService)['GetQuickFillMessageHistory']>;

export const getQuickFillMessageHistory = (request: SchemaGetQuickFillMessageHistory['input'], locationId?: string) => {
  return SchemaScheduleService.GetQuickFillMessageHistory(request, getLocationIdHeader(locationId ?? ''));
};
