import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { buildTrackingId } from '../tracking-ids';
import { PaymentActionEnum } from './types';

export const PaymentActionTrackingIds: Record<PaymentActionEnum, string> = {
  [PaymentActionEnum.CREATE_ACCOUNT_LEARN_MORE]: 'payment-action-create-account-learn-more',
  [PaymentActionEnum.CREATE_ACCOUNT]: 'payment-action-create-account',
  [PaymentActionEnum.CREATE_INVOICE]: 'payment-action-create-invoice',
  [PaymentActionEnum.DISMISS]: 'payment-action-dismiss',
  [PaymentActionEnum.ONLINE_BILL_PAY]: 'payment-action-online-bill-pay',
  [PaymentActionEnum.SHARE_INVOICE]: 'payment-action-share-invoice',
};

export const CommonActionTrackingIds = {
  COLLAPSE_CARD: 'action-collapse-card',
  EXPAND_CARD: 'action-expand-card',
  DISMISS: 'action-dismiss-card',
  REMIND_IN_3_DAYS: 'action-remind-in-3-days',
  NEVER_SHOW_AGAIN: 'action-never-show-again',
};

export const PaymentsPromoButtonTrackingIds = {
  REQUEST_PAYMENT: 'request-payment-promo-button',
};

export const getPaymentHVATrackingIds = (params: Omit<Parameters<typeof buildTrackingId>[0], 'feature'>) =>
  buildTrackingId({ ...params, feature: Feature.WEAVE_PAY });
