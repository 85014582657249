import { useEffect } from 'react';
import { css } from '@emotion/react';
import { useSearch } from '@tanstack/react-location';
import { QuickFillHooks } from '@frontend/api-quick-fill';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Tabs } from '@frontend/design-system';
import { QUICK_FILL_TRACKING_IDS } from '../../tracking-ids';
import { useSetRecipientCounts } from './hooks';
import { ManageListTabContent } from './manage-list';
import { SendMessagesTabContent } from './send-messages';
import { useQuickFillRecipientShallowStore } from './store';

enum PanelTabs {
  SendMessages = 'send-messages',
  ManageList = 'manage-list',
}

const trackingIds = QUICK_FILL_TRACKING_IDS.sidePanel;

export const TrayContent = () => {
  const { t } = useTranslation('schedule-quick-fill');
  const params = useSearch<{ Search: { quickFillTray: PanelTabs } }>();
  const { setValidLocationIds } = useQuickFillRecipientShallowStore('setValidLocationIds');
  const { selectedLocationIds } = useAppScopeStore();
  const { locationIdWiseQuickFillFeature } = QuickFillHooks.useQuickFillFeatureDetails();

  // Set recipient counts for locations
  useSetRecipientCounts();

  // Set valid location ids
  useEffect(() => {
    if (locationIdWiseQuickFillFeature) {
      const validLocationIds = selectedLocationIds.filter(
        (locationId) => locationIdWiseQuickFillFeature[locationId] === true
      );
      setValidLocationIds(validLocationIds);
    }
  }, [locationIdWiseQuickFillFeature, selectedLocationIds]);

  return (
    <Tabs initialTab={params?.quickFillTray ?? PanelTabs.SendMessages}>
      <Tabs.Bar css={{ padding: theme.spacing(0, 3) }}>
        <Tabs.Tab id={PanelTabs.SendMessages} trackingId={trackingIds.sendMessageTabButton}>
          {t('Send Messages')}
        </Tabs.Tab>
        <Tabs.Tab id={PanelTabs.ManageList} trackingId={trackingIds.manageListTabButton}>
          {t('Manage List')}
        </Tabs.Tab>
      </Tabs.Bar>
      <Tabs.Panel css={tabContentStyle} controller={PanelTabs.SendMessages} keepMounted={false}>
        <SendMessagesTabContent />
      </Tabs.Panel>
      <Tabs.Panel css={tabContentStyle} controller={PanelTabs.ManageList} keepMounted={false}>
        <ManageListTabContent />
      </Tabs.Panel>
    </Tabs>
  );
};

const tabContentStyle = css`
  height: 100%;
  overflow-y: auto;
  background-color: ${theme.colors.neutral5};
  padding: ${theme.spacing(4, 3)};
`;
