import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import {
  Chip,
  NakedButton,
  PopoverDialog,
  PrimaryButton,
  RadioField,
  useFormField,
  usePopoverDialog,
} from '@frontend/design-system';

type LocationFilterProps = {
  selectedLocationId: string;
  setSelectedLocationId: (locationId: string) => void;
  initialSelectedLocationId?: string;
  locationIds: string[];
};

export const LocationFilter = ({
  selectedLocationId,
  setSelectedLocationId,
  initialSelectedLocationId,
  locationIds,
}: LocationFilterProps) => {
  const { getScopeName } = useAppScopeStore();
  const { t } = useTranslation('scheduleRequestHistoryTable');

  const locationSelectorRadioField = useFormField({ type: 'radio', value: initialSelectedLocationId }, [
    initialSelectedLocationId,
  ]);
  const { getTriggerProps, getDialogProps, close, open, isOpen } = usePopoverDialog({
    placement: 'bottom-start',
    middlewareOptions: { offset: 0 },
  });

  const { ref: filterTriggerRef, ...triggerProps } = getTriggerProps();

  const handleLocationSelectorChange = (locationId: string) => {
    setSelectedLocationId(locationId);
    close();
  };

  return (
    <NakedButton ref={filterTriggerRef} css={{ display: 'flex', alignItems: 'center', marginRight: 'auto' }}>
      <Chip.DropdownFilter
        filterIsActive={selectedLocationId !== ''}
        {...triggerProps}
        onClick={isOpen ? close : open}
        css={{ width: 'unset', maxWidth: 'unset' }}
        leftElement={<Icon name='location-small' color={selectedLocationId === '' ? 'default' : 'white'} size={18} />}
      >
        {selectedLocationId === '' ? t('All locations') : getScopeName(selectedLocationId).replace(/-/g, ' ')}
      </Chip.DropdownFilter>
      <PopoverDialog {...getDialogProps()}>
        <RadioField {...locationSelectorRadioField} name={t('location-selector')}>
          {locationIds.map((locationId) => (
            <RadioField.Radio key={locationId} value={locationId} trackingId={`location-selector-${locationId}`}>
              {getScopeName(locationId)}
            </RadioField.Radio>
          ))}
        </RadioField>
        <PrimaryButton onClick={() => handleLocationSelectorChange(locationSelectorRadioField.value)}>
          {t('Apply')}
        </PrimaryButton>
      </PopoverDialog>
    </NakedButton>
  );
};
