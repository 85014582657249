import { useCallback, useMemo } from 'react';
import { addDays } from 'date-fns';
import { useLocalStorage } from '@frontend/web-storage';

const STORAGE_KEY = 'dismissed-items';
type DismissItemData = {
  expiry?: string;
};

export const useDismissHandler = (itemKey: string) => {
  const [dismissedItems, setDismissedItems] = useLocalStorage<Record<string, DismissItemData>>({
    key: STORAGE_KEY,
    defaultValue: {},
    subscribeToChanges: true,
  });

  const isDismissed = useMemo(() => {
    return dismissedItems?.[itemKey]?.expiry
      ? new Date(dismissedItems[itemKey].expiry) > new Date()
      : !!dismissedItems?.[itemKey];
  }, [dismissedItems, itemKey]);

  const handleDismiss = useCallback(
    (snoozeDays?: number) => {
      const expiryDateISO = snoozeDays ? addDays(new Date(), snoozeDays).toISOString() : '';
      setDismissedItems({
        ...dismissedItems,
        [itemKey]: {
          expiry: expiryDateISO,
        },
      });
    },
    [dismissedItems, itemKey]
  );

  return {
    isDismissed,
    handleDismiss,
  };
};
